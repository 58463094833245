import React, { useState } from 'react';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import '../styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';
import { addDays, format } from 'date-fns';

const Card = ({ report }) => {
  const [expandedItems, setExpandedItems] = useState({});

  const handleValueIsExpanded = (itemId) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [itemId]: !prevExpandedItems[itemId],
    }));
  };
  const groupedSaleCodes = new Set();
  report.forEach((data) => {
    data.results.forEach((element) => {
      if (element.category === 'Receitas Operacional Bruta') {
        element.itens
          .filter((item) => item.isTitle && item.sales?.length > 0)
          .forEach((item) => {
            item.sales.forEach((sale) => {
              groupedSaleCodes.add(sale.saleCode);
            });
          });
      }
    });
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="income-statement__card">
        <div className="income-statement__card-div">
          {report.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <div className="income-statement__card-info">
                  <section>{data.title}</section>
                  <section
                    style={{
                      color:
                        index === 0 || data.title === '(=) Lucro/Prejuízo'
                          ? data.total >= 0
                            ? '#6ab187'
                            : data.total === 0
                            ? '#444444'
                            : '#d9534f'
                          : '#d9534f',
                    }}
                  >
                    {currency(data.total)}
                  </section>
                </div>
                <div className="income-statement__card__sub-items">
                  {data.results.map((element, subIndex) => {
                    const itemId = `${index}-${subIndex}`;

                    return (
                      <div
                        className="income-statement__card__sub-items__item"
                        key={itemId}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                          }}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={
                                element.description === 'Devoluções de Venda'
                                  ? ''
                                  : expandedItems[itemId]
                                  ? faSquareMinus
                                  : faSquarePlus
                              }
                              style={{
                                cursor: 'pointer',
                                color: expandedItems[itemId]
                                  ? '#d9534f'
                                  : '#4cae4c',
                                width: '10px',
                                height: '10px',
                              }}
                              onClick={() => {
                                handleValueIsExpanded(itemId);
                              }}
                            />
                            ({element.simbol}) {element.description}
                          </span>
                          <div
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: '#444444',
                            }}
                          >
                            {currency(
                              isNaN(element.value || element.total)
                                ? 0
                                : element.value || element.total
                            )}
                          </div>
                        </div>
                        {element.category === 'Receitas Operacional Bruta' ? (
                          <Collapse in={expandedItems[itemId]}>
                            <div style={{ width: '100%' }}>
                              {element.itens.some((item) => item.isSale) ? (
                                <table
                                  style={{
                                    width: '100%',
                                    margin: '10px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <thead
                                    style={{
                                      fontSize: '11px',
                                      fontWeight: 'normal',
                                      color: '#444444',
                                      textTransform: 'none',
                                    }}
                                    className="borderBottom"
                                  >
                                    <tr>
                                      <th
                                        style={{
                                          maxWidth: '10px',
                                          textAlign: 'left',
                                        }}
                                      >
                                        Venda
                                      </th>
                                      <th
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '30px',
                                        }}
                                      >
                                        Cliente
                                      </th>
                                      <th
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        Total Peças
                                      </th>
                                      <th
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        Total Serviço
                                      </th>
                                      <th
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        Desconto Geral
                                      </th>
                                      <th
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    style={{
                                      fontSize: '10px',
                                      color: '#0000000',
                                      fontWeight: 'normal',
                                      fontFamily: 'Roboto',
                                    }}
                                    className="borderBottom"
                                  >
                                    {element.itens
                                      .filter(
                                        (item) =>
                                          item.isSale &&
                                          !groupedSaleCodes.has(item.code)
                                      )
                                      .map((item, itemIndex) => (
                                        <tr key={itemIndex}>
                                          <td
                                            style={{
                                              maxWidth: '10px',
                                              textAlign: 'left',
                                            }}
                                          >
                                            {item.code}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '30px',
                                            }}
                                          >
                                            {item.customer}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '15px',
                                            }}
                                          >
                                            {currency(item.totalProduct)}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '15px',
                                            }}
                                          >
                                            {currency(item.totalService)}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '15px',
                                            }}
                                          >
                                            {currency(
                                              item.discountGeneral || 0
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              maxWidth: '15px',
                                            }}
                                          >
                                            {currency(item.finalValue)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              ) : (
                                ''
                              )}

                              {element.itens.some(
                                (item) => item.isTitle && !item.specialCategory
                              ) ? (
                                <div style={{ width: '100%' }}>
                                  <table
                                    style={{
                                      width: '100%',
                                      margin: '10px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <thead
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: 'normal',
                                        color: '#444444',
                                        textTransform: 'none',
                                      }}
                                      className="borderBottom"
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            maxWidth: '10px',
                                            textAlign: 'left',
                                          }}
                                        >
                                          Título Individuais
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '30px',
                                          }}
                                        >
                                          Cliente
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Bruto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Acréscimo
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Desconto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Líquido
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Data de Criação
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      style={{
                                        fontSize: '10px',
                                        color: '#0000000',
                                        fontWeight: 'normal',
                                        fontFamily: 'Roboto',
                                      }}
                                      className="borderBottom"
                                    >
                                      {element.itens
                                        .filter(
                                          (item) =>
                                            item.isTitle &&
                                            !item.specialCategory &&
                                            item.sales?.length === 0
                                        )
                                        .map((item, itemIndex) => (
                                          <tr key={itemIndex}>
                                            <td
                                              style={{
                                                maxWidth: '10px',
                                                textAlign: 'left',
                                              }}
                                            >
                                              {item.titleCode}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '30px',
                                              }}
                                            >
                                              {item.customerTradingName ||
                                                item.customerCompanyName}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.grossValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.aditionValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.discountValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.finalValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {format(
                                                new Date(item.createdAt),
                                                'dd/MM/yyyy'
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                ''
                              )}
                              {element.itens.some(
                                (item) => item.isTitle && item.sales.length > 0
                              ) ? (
                                <div style={{ width: '100%' }}>
                                  <table
                                    style={{
                                      width: '100%',
                                      margin: '10px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <thead
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: 'normal',
                                        color: '#444444',
                                        textTransform: 'none',
                                      }}
                                      className="borderBottom"
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            maxWidth: '10px',
                                            textAlign: 'left',
                                          }}
                                        >
                                          Títulos Agrupados
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '30px',
                                          }}
                                        >
                                          Cliente
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Bruto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Acréscimo
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Desconto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Líquido
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Data de Criação
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody
                                      style={{
                                        fontSize: '10px',
                                        color: '#0000000',
                                        fontWeight: 'normal',
                                        fontFamily: 'Roboto',
                                      }}
                                      className="borderBottom"
                                    >
                                      {element.itens
                                        .filter(
                                          (item) =>
                                            item.isTitle &&
                                            item.sales.length > 0
                                        )
                                        .map((item, itemIndex) => (
                                          <tr key={itemIndex}>
                                            <td
                                              style={{
                                                maxWidth: '10px',
                                                textAlign: 'left',
                                              }}
                                            >
                                              {item.titleCode}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '30px',
                                              }}
                                            >
                                              {item.customerTradingName ||
                                                item.customerCompanyName}
                                              <td className="title_grouped_sales">
                                                Vendas:
                                                {item.sales.map(
                                                  (sale, index) => (
                                                    <div key={index}>
                                                      <td
                                                        style={{
                                                          fontWeight: 'bold',
                                                          fontStyle: 'italic',
                                                          color:
                                                            sale.saleStatus ===
                                                            5
                                                              ? '#D43F3A'
                                                              : 'black',
                                                        }}
                                                      >
                                                        {sale.saleCode}
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: 'black',
                                                        }}
                                                      >
                                                        {index <
                                                        item.sales.length - 1
                                                          ? ', '
                                                          : ''}
                                                      </td>
                                                    </div>
                                                  )
                                                )}
                                              </td>
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.grossValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.aditionValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.discountValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.finalValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {format(
                                                new Date(item.createdAt),
                                                'dd/MM/yyyy'
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                ''
                              )}
                              {element.itens.some(
                                (item) => item.specialCategory
                              ) ? (
                                <div style={{ width: '100%' }}>
                                  <table
                                    style={{
                                      width: '100%',
                                      margin: '10px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <thead
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: 'normal',
                                        color: '#444444',
                                        textTransform: 'none',
                                      }}
                                      className="borderBottom"
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            maxWidth: '10px',
                                            textAlign: 'left',
                                          }}
                                        >
                                          Títulos de Gera Diferença
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '30px',
                                          }}
                                        >
                                          Cliente
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Bruto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Acréscimo
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Desconto
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Valor Líquido
                                        </th>
                                        <th
                                          style={{
                                            textAlign: 'left',
                                            maxWidth: '15px',
                                          }}
                                        >
                                          Data de Criação
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody
                                      style={{
                                        fontSize: '10px',
                                        color: '#0000000',
                                        fontWeight: 'normal',
                                        fontFamily: 'Roboto',
                                      }}
                                      className="borderBottom"
                                    >
                                      {element.itens
                                        .filter((item) => item.specialCategory)
                                        .map((item, itemIndex) => (
                                          <tr key={itemIndex}>
                                            <td
                                              style={{
                                                maxWidth: '10px',
                                                textAlign: 'left',
                                              }}
                                            >
                                              {item.titleCode}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '30px',
                                              }}
                                            >
                                              {item.customerTradingName ||
                                                item.customerCompanyName}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.grossValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.aditionValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.discountValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {currency(item.finalValue)}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: 'left',
                                                maxWidth: '15px',
                                              }}
                                            >
                                              {format(
                                                new Date(item.createdAt),
                                                'dd/MM/yyyy'
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </Collapse>
                        ) : element.description === 'Devoluções de Venda' ? (
                          <div></div>
                        ) : (
                          <Collapse in={expandedItems[itemId]}>
                            <div style={{ width: '100%' }}>
                              <table
                                style={{
                                  width: '100%',
                                  margin: '10px',
                                  textAlign: 'center',
                                }}
                              >
                                <thead
                                  style={{
                                    fontSize: '11px',
                                    fontWeight: 'normal',
                                    color: '#444444',
                                    textTransform: 'none',
                                  }}
                                  className="borderBottom"
                                >
                                  <tr>
                                    <th
                                      style={{
                                        maxWidth: '10px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Título
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '30px',
                                      }}
                                    >
                                      Fornecedor
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '15px',
                                      }}
                                    >
                                      Valor Bruto
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '15px',
                                      }}
                                    >
                                      Acréscimo
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '15px',
                                      }}
                                    >
                                      Desconto
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '15px',
                                      }}
                                    >
                                      Valor Líquido
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'left',
                                        maxWidth: '15px',
                                      }}
                                    >
                                      Data de Criação
                                    </th>
                                  </tr>
                                </thead>
                                <tbody
                                  style={{
                                    fontSize: '10px',
                                    color: '#0000000',
                                    fontWeight: 'normal',
                                    fontFamily: 'Roboto',
                                  }}
                                  className="borderBottom"
                                >
                                  {element.itens.map((item, itemIndex) => (
                                    <tr key={itemIndex}>
                                      <td
                                        style={{
                                          maxWidth: '10px',
                                          textAlign: 'left',
                                        }}
                                      >
                                        {item.code}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '30px',
                                        }}
                                      >
                                        {item.provider}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        {currency(item.grossValue)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        {currency(item.aditionValue)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        {currency(item.discountValue)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        {currency(item.liquidValue)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: 'left',
                                          maxWidth: '15px',
                                        }}
                                      >
                                        {format(
                                          addDays(new Date(item.createdAt), 1),
                                          'dd/MM/yyyy'
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Collapse>
                        )}
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Card;
