import api from 'v2/services/api';

const BASE_URL = 'kanban-yard-management';

export const getColumns = async (companyId, showType, showFinished) => {
  try {
    const res = await api.get(
      `${BASE_URL}/${companyId}?showType=${showType}&showFinished=${showFinished}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createColumn = async (column) => {
  const res = await api.post(`${BASE_URL}/column`, { column });
  return res.data;
};

export const updateColumn = async (column, companyId) => {
  const res = await api.put(`${BASE_URL}/column/${companyId}`, {
    column,
  });
  return res.data;
};

export const deleteColumn = async (companyId, columnId) => {
  try {
    const res = await api.delete(`${BASE_URL}/column/${companyId}/${columnId}`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const moveColumn = async (companyId, column) => {
  try {
    const res = await api.put(`${BASE_URL}/move-column/${companyId} `, {
      column,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const statusCard = async (id, yardManagementId) => {
  try {
    const res = await api.put(`${BASE_URL}/move-card`, {
      id,
      yardManagementId,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const YardManagementRepository = {
  getColumns,
  createColumn,
  updateColumn,
  deleteColumn,
  statusCard,
  moveColumn,
};
