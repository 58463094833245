import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';
import { milhar, onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { yearMask } from 'utils/masks';
import '../styles.css';
import { SelectBrand } from 'v2/client/components/SelectBrand';

const CarMotobikeTruckForm = ({
  RenderField,
  handleModelChange,
  handleOpenModal,
  isViewingData,
  dispatch,
  change,
  Brand,
  BranchId,
}) => {
  const [brandSelected, setBrandSelected] = useState(null);
  const handleBrandChange = (item) => {
    setBrandSelected(item);
    dispatch(
      change(
        'vehicle',
        'Brand',
        item && item.value ? item.value.description : ''
      )
    );
  };
  return (
    <>
      <div className="form-new-vehicle__row">
        <div>
          <span>
            Obj. de Manutenção: <span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            name="Maintenance_Object"
            component={RenderField}
            required
            disabled={isViewingData}
          />
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              Marca: <span style={{ color: 'red' }}>*</span>{' '}
            </span>
            {!isViewingData && (
              <span>
                <a
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: '----',
                    textDecoration: 'none',
                  }}
                  onClick={() =>
                    handleOpenModal('Nova Marca', { marginBottom: '65px' })
                  }
                >
                  <u>Adicionar Marca</u>
                </a>
              </span>
            )}
          </div>
          <SelectBrand
            value={
              brandSelected || Brand
                ? {
                    value: { id: null, description: Brand },
                    label: Brand,
                  }
                : null
            }
            onChange={handleBrandChange}
            width="100%"
            type={['manufacturer']}
            disabled={isViewingData}
          />
        </div>

        <div style={{ width: 400 }}>
          <span>
            Modelo: <span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            disabled={isViewingData}
            name="Model"
            component={RenderField}
            onChange={(model) => handleModelChange(model.value)}
            required
          />
        </div>
      </div>

      <div className="form-new-vehicle__row" style={{ marginTop: '10px' }}>
        <div style={{ width: '80px' }}>
          <span htmlFor="year">
            Ano <strong>Fab.</strong>:
            {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          </span>
          <Field
            disabled={isViewingData}
            name="Year_Manufacture"
            component={RenderField}
            {...yearMask}
          />
        </div>

        <div style={{ width: '80px' }}>
          <span htmlFor="modelYear">
            Ano <strong>Mod.</strong>:
            {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          </span>
          <Field
            name="Year_Model"
            component={RenderField}
            {...yearMask}
            disabled={isViewingData}
          />
        </div>

        <div>
          <span>KM: </span>
          {BranchId !== 5 && <span style={{ color: 'red' }}>*</span>}
          <Field
            disabled={isViewingData}
            name="Kilometers"
            required
            component={RenderField}
            normalize={(value) => milhar(onlyNumbers(value))}
            maxLength={10}
            style={{ width: '120px' }}
          />
        </div>

        <div>
          <span>Cor:</span>
          <Field
            disabled={isViewingData}
            name="Color"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <span>Chassi/Série:</span>
          <Field
            disabled={isViewingData}
            name="NChassi"
            component={RenderField}
            normalize={(value) => onlyNumbersLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <span>Tipo de Combustível</span>
          <Field
            label="Tipo de Combustível"
            name="Fuel_Type"
            component="select"
            placeholder="Selecione"
            className="form-control foco-input"
            disabled={isViewingData}
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Elétrico">Elétrico</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Elétrico">
              Híbrido (Gasolina/Elétrico)
            </option>
            <option value="Outro">Outro</option>
          </Field>
        </div>
      </div>
    </>
  );
};

export default CarMotobikeTruckForm;
