import React from 'react';
import { Modal } from 'react-bootstrap';

export function LimitExceedModalNFe({
  isOpen,
  handleClose,
  parcelsOnManySales,
}) {
  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-35w">
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <strong>Limite de formas de pagamento</strong>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: '14px', lineHeight: '1.5' }}>
          <strong>Quantidade limite de forma de pagamento atingido</strong>
          <br />
          <br />
          Essa nota contém um total de {parcelsOnManySales} formas de pagamento
          e a SEFAZ limita a 120 formas de pagamentos que podem ser enviados na
          nota fiscal. Exclua a nota atual e crie uma nova dentro do limite, se
          necessário, use mais de uma nota.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={handleClose}
          >
            <span>Voltar</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
