import React from 'react';
import { Modal } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  IdeaReviewModalContainer,
  IdeaReviewButton,
} from './IdeaReviewForm.styles';
import { IdeaReviewCustomerData } from './IdeaReviewCustomerData';
import { IdeaReviewForm } from './IdeaReviewForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEyeSlash,
  faThumbsUp,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { useIdeaWall } from '../../hooks/useIdeaWall';
import { useAdminAuth } from 'contexts/adminAuth';

export function IdeaReviewModal({ handleClose }) {
  const { watch, setValue, getValues } = useFormContext();
  const { updateIdeaWallData, getIdeasWallData, isLoading } = useIdeaWall();
  const { admin } = useAdminAuth();

  const { isOpenModal, form } = watch('ideaWallForm');
  const feedback = watch('ideaWallForm.updateForm.feedback') || '';
  const isResponseEmpty = feedback.trim().length === 0;
  const status = form?.statusDescription;

  const handleUpdatePost = async (statusId) => {
    await updateIdeaWallData(form.id, statusId, admin.id, getValues);
    getIdeasWallData(setValue, getValues, 'table');
    handleClose();
  };

  return (
    <Modal show={isOpenModal} onHide={handleClose} dialogClassName="modal-70w">
      <Modal.Header style={{ padding: '5px 10px' }}>
        <Modal.Title>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <strong>Status da Ideia</strong>
            <FontAwesomeIcon
              icon={faX}
              style={{ cursor: 'pointer', width: '14px', height: '14px' }}
              onClick={handleClose}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '0px' }}>
        <IdeaReviewModalContainer>
          <IdeaReviewCustomerData {...form} />
          <IdeaReviewForm {...form} />
        </IdeaReviewModalContainer>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end row gap-050">
          <IdeaReviewButton
            className="button"
            disabled={
              isLoading ||
              (status === 'Nova Ideia'
                ? !isResponseEmpty
                : status === 'Ideia Aprovada'
                ? false
                : true)
            }
            bgColor="#5CB85C"
            bdColor="#4CAE4C"
            onClick={() => handleUpdatePost(2)}
          >
            Entregar
            <FontAwesomeIcon icon={faCheck} />
          </IdeaReviewButton>

          {/* Botão Ocultar */}
          <IdeaReviewButton
            bgColor="#2C3F50"
            bdColor="#142A3D"
            className="button"
            // Se for Nova Ideia, ativa somente se houver preenchimento no campo de resposta;
            // Caso contrário, inativa.
            disabled={
              isLoading ||
              (status === 'Nova Ideia' ? isResponseEmpty : true)
            }
            onClick={() => handleUpdatePost(5)}
          >
            Ocultar
            <FontAwesomeIcon icon={faEyeSlash} />
          </IdeaReviewButton>

          {/* Botão Rejeitar */}
          <IdeaReviewButton
            className="button"
            bgColor="#D43F3A"
            bdColor="#D9534F"
            // Mesma regra do botão Ocultar
            disabled={
              isLoading ||
              (status === 'Nova Ideia' ? isResponseEmpty : true)
            }
            onClick={() => handleUpdatePost(4)}
          >
            Rejeitar
            <FontAwesomeIcon icon={faX} />
          </IdeaReviewButton>

          {/* Botão Aprovar */}
          <IdeaReviewButton
            className="button"
            bgColor="#428BCA"
            bdColor="#357EBD"
            // Se for Nova Ideia, ativa somente se o campo de resposta estiver vazio;
            // Nos demais status (inclusive Ideia Aprovada), inativa.
            disabled={
              isLoading ||
              (status === 'Nova Ideia' ? !isResponseEmpty : true)
            }
            onClick={() => handleUpdatePost(1)}
          >
            Aprovar
            <FontAwesomeIcon icon={faThumbsUp} />
          </IdeaReviewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
