import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomizedTabs from 'v2/components/Tabs/Tabs';
import Header from './Header';
import { FormProvider, useForm } from 'react-hook-form';
import { SaleConfigRepository } from 'v2/repositories/SaleConfigRepository';
import { useAuth } from 'contexts/auth';
import { defaultValues } from './constants';
import OtherDetails from './OtherDetails';
import { toastr } from 'react-redux-toastr';

export default function PrintConfigModal({ show, onHide }) {
  const methods = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { setValue, watch } = methods;
  const loading = watch('loading');

  const [selectedTab, setSelectedTab] = useState(0);
  const { companyId } = useAuth();

  function handleTabChange(event, newValue) {
    setSelectedTab(newValue);
  }

  const renderTabs = () => {
    const tabLabels = ['Cabeçalho', 'Outros Detalhes'];

    return (
      <CustomizedTabs
        tabLabels={tabLabels}
        value={selectedTab}
        onChange={handleTabChange}
      />
    );
  };

  function renderTabContent(index) {
    switch (index) {
      case 0:
        return <Header />;
      case 1:
        return <OtherDetails />;
      default:
        return <Header />;
    }
  }

  const loadConfig = async () => {
    try {
      setValue('loading', true);
      const config = await SaleConfigRepository.getByCompanyId(companyId);

      if (config.status === true && config.data) {
        methods.reset({
          id: config.data.id,
          loading: false,
          header: {
            companyName: config.data.companyName,
            customerName: config.data.customerName,
            createdFormat: config.data.createdFormat,
          },
          otherDetails: {
            showEmptyDiscount: config.data.showEmptyDiscount,
            observation: config.data.observation,
            defects: config.data.defects,
            malfunctions: config.data.malfunctions,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setValue('loading', false);
    }
  };

  const handleSerializeData = (values) => ({
    companyId: companyId,
    companyName: values.header.companyName,
    customerName: values.header.customerName,
    createdFormat: values.header.createdFormat,
    showEmptyDiscount: values.otherDetails.showEmptyDiscount,
    observation: values.otherDetails.observation,
    defects: values.otherDetails.defects,
    malfunctions: values.otherDetails.malfunctions,
  });

  const create = async (data) => {
    try {
      setValue('loading', true);
      await SaleConfigRepository.create(data);

      toastr.success('Configuração salva com sucesso!');
      onHide();
    } catch (error) {
      console.error(error);
      toastr.error(
        'Ocorreu um erro ao salvar a configuração.',
        'Por favor, tente novamente.'
      );
    } finally {
      setValue('loading', false);
    }
  };

  const update = async (id, data) => {
    try {
      setValue('loading', true);
      await SaleConfigRepository.update(id, data);

      toastr.success('Configuração salva com sucesso!');
      onHide();
    } catch (error) {
      console.error(error);
      toastr.error(
        'Ocorreu um erro ao salvar a configuração.',
        'Por favor, tente novamente.'
      );
    } finally {
      setValue('loading', false);
    }
  };

  const onSubmit = async (values) => {
    const serializedData = handleSerializeData(values);

    if (values.id) await update(values.id, serializedData);
    else await create(serializedData);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Modal show={show} onHide={() => onHide()} dialogClassName="modal-40w">
        <Modal.Header>
          <Modal.Title>
            <div className="flex w-100 justify-between align-center">
              <strong>Impressos de Venda</strong>
              <span className="text-red fontSize-12">
                Apenas os modelos 1 e 2 de Orçamento, Venda e OS serão afetados
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '5px' }}>
          <FormProvider {...methods}>
            {renderTabs()}
            {renderTabContent(selectedTab)}
          </FormProvider>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-075">
            <button
              className="button button-red button-h35"
              onClick={() => onHide()}
              disabled={loading}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="button button-green button-h35"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={loading}
            >
              Salvar
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </form>
  );
}
