import React from 'react';
import { useHistory } from 'react-router-dom';

export const KanbanNewScheduling = ({
  description,
  urlRedirect,
  buttonTitle,
}) => {
  const history = useHistory();

  return (
    <div
      id="newScheduling"
      className="div-a-modal w-100 flex center justify-center p-0"
    >
      {description !== 'Finalizado' && (
        <a
          onClick={() => {
            history.push(`${urlRedirect}`);
          }}
          className="underline pointer text-green typography__text-2"
        >
          {buttonTitle}
        </a>
      )}
    </div>
  );
};
