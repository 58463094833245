import { atom } from 'recoil';

export const ALL_ALERTS_STATE = atom({
  key: 'allAlerts',
  default: [],
});

export const ALERTS_COUNT_STATE = atom({
  key: 'alertsCount',
  default: 0,
});

export const NOT_VISUALIZED_ALERTS_STATE = atom({
  key: 'has',
  default: false,
});
