import React, { useState } from 'react';
import { format } from 'date-fns';
import { IdeaWallStatusTable } from '../components/IdeaWallStatusTable';

export const useConfigIdeaWallColumns = (setValue) => {
  const CategoryBadge = ({ category, subcategory }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <strong>{category}</strong>
        <small>{subcategory}</small>
      </div>
    );
  };
  const Customer = ({ customerInfo }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <strong style={{ fontSize: '14px' }}>
          {customerInfo.original.companyName}
        </strong>
        <small>
          {customerInfo.original.planName} -{' '}
          {customerInfo.original.brandingName}
        </small>
      </div>
    );
  };

  const columns = [
    {
      Header: 'Dt. Criação',
      accessor: 'createdAt',
      width: 120,

      Cell: (props) =>
        props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
    },
    {
      Header: 'Título',
      accessor: 'title',

      Cell: (props) => {
        return (
          <a
            style={{
              color: '#007bff',
              textDecoration: 'underline',
            }}
            href="#"
            onClick={() => {
              setValue('ideaWallForm', {
                isOpenModal: true,
                form: props.original,
              });
            }}
          >
            {props.value}
          </a>
        );
      },
    },

    {
      Header: 'Categoria',
      accessor: 'categoryDescription',
      width: 140,
      Cell: (props) => (
        <CategoryBadge
          category={props.value}
          subcategory={props.original.subCategoryDescription}
        />
      ),
    },
    {
      Header: 'Status',
      accessor: 'statusDescription',
      width: 150,
      Cell: (props) => <IdeaWallStatusTable label={props.value} />,
    },
    {
      Header: 'Cliente',
      accessor: 'companyName',
      Cell: (props) => <Customer customerInfo={props} />,
    },

    {
      Header: 'Votos',
      accessor: 'votesCount',
      width: 80,
    },
    {
      Header: 'Dt. Apr/Rej',
      accessor: '',
      Cell: (props) => {
        const { refusedAt, approvedAt } = props?.original || {};
        const date = refusedAt || approvedAt;
        return date ? format(new Date(date), 'dd/MM/yyyy') : '-';
      },
      width: 120,
    },

    {
      Header: 'Dt. Entregue',
      accessor: 'deliveredAt',
      Cell: (props) => {
        return props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-';
      },
      width: 120,
    },
  ];

  const columnsSheet = [
    {
      name: 'Dt. Criação',
      acessor: 'createdAt',
    },
    {
      name: 'Título',
      acessor: 'title',
    },
    {
      name: 'Categoria',
      acessor: 'categoryDescription',
    },
    {
      name: 'SubCategoria',
      acessor: 'subCategoryDescription',
    },
    {
      name: 'Status',
      acessor: 'statusDescription',
    },
    {
      name: 'Cliente',
      acessor: 'companyName',
    },
    {
      name: 'Votos',
      acessor: 'votesCount',
    },
    {
      name: 'Dt. Rejeição',
      acessor: 'refusedAt',
    },
    {
      name: 'Dt. Aprovação',
      acessor: 'approvedAt',
    },
    {
      name: 'Dt. Entrega',
      acessor: 'deliveredAt',
    },
  ];

  return { columns, columnsSheet };
};
