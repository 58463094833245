import { toastr } from 'react-redux-toastr';
import { useYardManagement } from './useYardManagement';

export const useKanbanYardManagement = () => {
  const { moveColumn, moveCard, handleColumns } = useYardManagement();

  const handleColumnDragStart = (dragColumn, isDraggingCard, e, index) => {
    if (!isDraggingCard) {
      dragColumn.current = index;
    }
  };

  const handleColumnDragOver = (e) => {
    e.preventDefault();
  };

  const handleColumnDrop = async (
    e,
    isDraggingCard,
    dragColumn,
    columns,
    setColumns,
    index
  ) => {
    if (!isDraggingCard) {
      const oldIndex = dragColumn.current;
      if (oldIndex === index) return;

      const draggedColumn = columns[oldIndex];
      const targetColumn = columns[index];

      if (
        draggedColumn.id === null ||
        draggedColumn.id === 2 ||
        targetColumn.id === null ||
        targetColumn.id === 2
      ) {
        return toastr.warning(
          'Alterações não são permitidas para status ou colunas padrão.'
        );
      }

      const newColumns = [...columns];

      const draggedColumnData = newColumns.splice(oldIndex, 1)[0];
      newColumns.splice(index, 0, draggedColumnData);

      const column = {
        ...draggedColumnData,
        newOrder: index,
        oldOrder: oldIndex,
      };
      await moveColumn(column);
      setColumns(newColumns);
    }
  };
  const handleDragStart = (
    e,
    index,
    columns,
    setIsDraggingCard,
    dragItem,
    columnIndex
  ) => {
    e.stopPropagation();
    setIsDraggingCard(true);
    dragItem.current = {
      card: columns[columnIndex].cards[index],
      columnIndex,
      cardIndex: index,
      oldColumnIndex: columnIndex,
      oldCardIndex: index,
    };
  };

  const handleDragEnter = (e, dragItem, columnIndex, cardIndex) => {
    const {
      card,
      columnIndex: oldColumnIndex,
      cardIndex: oldCardIndex,
    } = dragItem.current;

    if (oldColumnIndex !== columnIndex) {
      dragItem.current.columnIndex = columnIndex;
      dragItem.current.cardIndex = cardIndex === -1 ? 0 : cardIndex;
    }
  };
  const handleDragEnd = async (
    columns,
    setColumns,
    setIsDraggingCard,
    dragItem,
    dragOverItem,
    onSuccess
  ) => {
    const newColumns = [...columns];
    const { card, columnIndex, cardIndex } = dragItem.current;
    const oldColumnIndex = dragItem.current.oldColumnIndex;
    const oldCardIndex = dragItem.current.oldCardIndex;

    if (
      newColumns[oldColumnIndex].cards &&
      newColumns[oldColumnIndex].cards.length > 1
    ) {
      newColumns[oldColumnIndex].cards.splice(oldCardIndex, 1);
    } else {
      newColumns[oldColumnIndex].cards = [];
    }
    if (
      !newColumns[columnIndex].cards ||
      newColumns[columnIndex].cards.length === 0
    ) {
      newColumns[columnIndex].cards = [card];
    } else {
      newColumns[columnIndex].cards.splice(cardIndex, 0, card);
    }

    const updatedColumn = newColumns[columnIndex];

    setColumns(newColumns);
    dragItem.current = null;
    dragOverItem.current = null;
    setIsDraggingCard(false);
    await moveCard(card.id, updatedColumn.id);
    if (updatedColumn.id === 2) {
      onSuccess();
    }
  };
  return {
    handleColumnDragStart,
    handleColumnDragOver,
    handleColumnDrop,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
  };
};
