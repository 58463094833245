import React from 'react';
import {
  headerCompanyNameOptions,
  headerCreatedFormatOptions,
  headerCustomerNameOptions,
} from './constants';
import { useFormContext } from 'react-hook-form';
import { useAuth } from 'contexts/auth';
export default function Header() {
  const { register, watch } = useFormContext();
  const { company } = useAuth();
  const isCpf = company.Type === 'Física';

  const loading = watch('loading');

  return (
    <div className="flex column gap-1 mt-05 p-1" style={{ marginLeft: '15px' }}>
      <div className="flex row gap-050 start align-center">
        <span>Nome da Empresa:</span>
        <select
          className="form-control foco-input w-250px"
          name="companyName"
          {...register('header.companyName')}
          disabled={loading}
        >
          {headerCompanyNameOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex row gap-050 start align-center">
        <span>Nome do Cliente (Se Empresa):</span>
        <select
          className="form-control foco-input w-250px"
          name="customerName"
          {...register('header.customerName')}
          disabled={loading}
        >
          {headerCustomerNameOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex row gap-050 start align-center">
        <span>Data e Hora de Criação:</span>
        <select
          className="form-control foco-input w-250px"
          name="createdFormat"
          {...register('header.createdFormat')}
          disabled={isCpf || loading}
        >
          {headerCreatedFormatOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
