import { co } from '@fullcalendar/core/internal-common';
import styled from 'styled-components';

export const IdeaReviewModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

export const IdeaReviewBoxLeft = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  background: #eeeeee;
  padding: 10px;

  strong {
    text-decoration: underline;
  }

  .infoCustomer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  .boxInfo {
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
    }
  }
  .postActionHistoryTime {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
`;

export const IdeaReviewBoxRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 15px;
`;

export const IdeaReviewFooter = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  gap: 0.5rem;
`;

export const IdeaReviewButton = styled.button`
  color: #fff;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border: 1px solid ${({ bdColor }) => bdColor || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: row;
`;
