import styled from 'styled-components';

export const BankSlipAlertButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ hasBankSlips }) =>
    hasBankSlips === 'Existem boletos a vencer'
      ? '#f79f00'
      : hasBankSlips === 'Existem boletos em atraso'
      ? '#FF0000'
      : '#00497b'};
  color: ${({ theme }) => theme.colors.white};
  height: 35px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  border: none;
  border-radius: 4px;
  padding: 7.5px;
  position: relative;
  white-space: nowrap;
  cursor: ${({ hasBankSlips }) => (hasBankSlips ? 'pointer' : 'default')};

  ${({ hasBankSlips }) =>
    hasBankSlips &&
    `
    &:hover {
      ${DropdownMenu} {
        display: block;
      }
    }
  `}
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0;
  background-color: ${(props) => props.theme.colors.branding1000};
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 140%;
  display: none;
`;

export const DropdownMenuItem = styled.span`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 0;
  gap: 0 5px;
  border-radius: ${(props) => props.theme.radii.sm};
`;
