import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';

import { handleChange } from './redux/actions';
import constants from 'utils/constants';

import schedulesRepository from 'repositories/Schedules';
import salesRepository from 'repositories/Sales';

import AlertModal from 'components/AlertModal';
import FormNewSchedule from '../../../Schedules/NewScheduling/FormNewScheduling';
import { change } from 'redux-form';
import { useSaleStore } from 'v2/store/saleStore';

function SchedulingModal({
  history,
  saleId,
  forceOpenModal = false,
  kanbanSchedulingId,
  updateScheduling,
}) {
  const dispatch = useDispatch();

  const { company } = useAuth();

  const { saleReducer } = useSelector((state) => state);
  const {
    items,
    saleTypeId,
    saleId: currentSaleId,
    saleStatusId,
  } = saleReducer;

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [isSchedulingUpdate, setIsSchedulingUpdate] = useState(false);
  const [schedulingId, setSchedulingId] = useState(0);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const hasService = items.filter((c) => c.Type === 'Serviço').length > 0;
  const isUpdate = !!saleId;

  const { saveFinishedSaleId } = useSaleStore((store) => {
    return {
      saveFinishedSaleId: store.saveFinishedSaleId,
    };
  });

  useEffect(() => {
    if (!company) return;

    handleOpenSchedulingModal();
  }, [saleId, company]);

  useEffect(() => {
    if (isSchedulingUpdate)
      setAlertModalMessage(
        `Deseja editar o agendamento ${
          saleTypeId === 3 ? 'dessa ordem de serviço' : 'desse orçamento'
        }? O agendamento poderá ser editado ou excluído posteriormente.`
      );
    else
      setAlertModalMessage(
        `Deseja realizar um agendamento ${
          saleTypeId === 3 ? 'dessa ordem de serviço' : 'desse orçamento'
        }? O agendamento poderá ser feito, editado ou excluído posteriormente.`
      );
  }, [isSchedulingUpdate]);

  function goToSales() {
    setTimeout(() => {
      toastr.success(
        !isUpdate
          ? `${
              saleTypeId === constants.SALES_TYPE_ID.ORCAMENT
                ? 'Orçamento salvo'
                : saleTypeId === constants.SALES_TYPE_ID.SALE
                ? 'Venda salva'
                : saleTypeId === constants.SALES_TYPE_ID.ORDER_OF_SERVICE &&
                  'O.S salva'
            } com sucesso`
          : 'Venda atualizada com sucesso.'
      );
      if (saleStatusId === constants.SALE_STATUS_ID.FINISHED)
        saveFinishedSaleId(currentSaleId);

      history.push(`${constants.ROUTES.SALES}`);
    }, 500);
  }

  async function handleOpenSchedulingModal() {
    if (
      !hasService ||
      saleStatusId !== constants.SALE_STATUS_ID.OPEN ||
      !company.schedulingOnSale
    )
      return goToSales();

    if (isUpdate) {
      const { data: saleSchedules } = await schedulesRepository.index({
        saleId,
      });
      const hasSchedulesOnThisSale = !!saleSchedules.length;

      if (hasSchedulesOnThisSale) {
        setIsSchedulingUpdate(true);
        setSchedulingId(saleSchedules[saleSchedules.length - 1].id);
      }
    }

    setIsAlertModalOpen(true);
  }

  async function initScheduleWithCurrentSale() {
    if (isSchedulingUpdate) return;

    try {
      const sale = await salesRepository.getById(currentSaleId);

      dispatch([
        change('formNewScheduling', 'number', sale.Code),
        change('formNewScheduling', 'customer', sale.Customer.Company_Name),
        change('formNewScheduling', 'licensePlate', sale?.License_Plate),
        change('formNewScheduling', 'vehicle', sale.Vehicle?.Model),
        change('formNewScheduling', 'vehicleId', sale.Vehicle?.id),
        change('formNewScheduling', 'saleId', sale.id),
        change('formNewScheduling', 'customerId', sale.Customer.id),
      ]);
    } catch (err) {
      console.error(err);
      toastr.warning('Ocorreu um erro ao carregar a venda no agendamento.');
    }
  }

  return (
    <>
      <AlertModal
        show={isAlertModalOpen}
        title="OS Digital"
        subtitle={
          isSchedulingUpdate
            ? `Existe um agendamento para ${
                saleTypeId === 3 ? 'essa ordem de serviço.' : 'esse orçamento.'
              }`
            : null
        }
        message={alertModalMessage}
        onHide={() => {
          setIsAlertModalOpen(false);
          goToSales();
        }}
        onCancel={() => {
          setIsAlertModalOpen(false);
          goToSales();
        }}
        onSubmit={() => {
          initScheduleWithCurrentSale();
          setIsSchedulingModalOpen(true);
          setIsAlertModalOpen(false);
        }}
      />
      {(isSchedulingModalOpen || forceOpenModal) && (
        <Modal
          show={isSchedulingModalOpen || forceOpenModal}
          onHide={() =>
            dispatch(
              handleChange(false, 'openSchedulingModal') || updateScheduling()
            )
          }
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Agendamento</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormNewSchedule
              onSubmit={() => {
                setIsSchedulingModalOpen(false);

                if (forceOpenModal) {
                  updateScheduling();
                  return;
                }
                goToSales();
              }}
              schedulingId={schedulingId || kanbanSchedulingId}
              isKanban={!!kanbanSchedulingId}
              isModal={true}
              closeModal={() => setIsSchedulingModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default withRouter(SchedulingModal);
