import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import BillsToPayTable from './BillsToPayTable';
import BillsToPayCreation from './BillsToPayCreation';
import billsToPayRepository from '../../../../repositories/BillsToPay';
import { useAuth } from 'contexts/auth';

import './styles.css';

const BillsToPayModal = ({ handleCancel, purchase, handleReloadPurchases }) => {
  const [billsToPay, setBillsToPay] = useState([]);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();

  useEffect(() => {
    loadBillsToPay();
  }, []);

  async function loadBillsToPay() {
    setLoading(true);
    try {
      const billsToPay = await billsToPayRepository.getAllByPurchase(
        purchase.id,
        companyId
      );
      setLoading(false);
      setBillsToPay(billsToPay.bills);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os títulos. Por favor, tente novamente'
      );
    }
  }

  async function handleSubmitBillsToPayCreation() {
    handleReloadPurchases();
    loadBillsToPay();
  }

  async function handleRecloseBillsToPay() {
    setLoading(true);

    try {
      await billsToPayRepository.reCloseByPurchase(purchase.id);
      setLoading(false);
      setBillsToPay([]);
      handleReloadPurchases();
    } catch (err) {
      setLoading(false);
      if (err.response) {
        return toastr.warning(err.response.data.message);
      }
      toastr.warning(
        'Ocorreu um erro ao realizar o novo fechamento. Por favor, tente novamente'
      );
    }
  }

  return (
    <Modal
      show
      onHide={handleCancel}
      dialogClassName="purchases-bills-to-pay-modal"
      animation
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <header>
            <h4>
              <strong>Títulos da Entrada de Compra {purchase.code}</strong>
            </h4>
            <p>
              <strong>Fornecedor:</strong> {purchase.Provider.companyName}
            </p>
            <p>
              <strong>Valor da Compra:</strong> {currency(purchase.total)}
            </p>
          </header>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div />
        ) : billsToPay.length ? (
          <BillsToPayTable
            billsToPay={billsToPay}
            handleCancel={handleCancel}
            onReClosing={handleRecloseBillsToPay}
          />
        ) : (
          <BillsToPayCreation
            handleCancel={handleCancel}
            purchase={purchase}
            onSubmit={handleSubmitBillsToPayCreation}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BillsToPayModal;
