import { parseISO, format } from 'date-fns';

export const kanbanUtils = {
  formatDateTime(dateTimeString) {
    return format(parseISO(dateTimeString), 'dd/MM/yyyy HH:mm');
  },

  descriptionSaleType: (id) => {
    const description = {
      1: 'Orçamento',
      2: 'Venda',
      3: 'OS',
      4: 'PDV',
    };

    return description[id] || ' - ';
  },
};
