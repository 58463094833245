import React from 'react';
import { Modal } from 'react-bootstrap';

export function LowBillPayShowModal({ isOpen, handleClose, handleSubmit }) {
  return (
    <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Confirmação de Baixa</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <strong>O caixa desse título não está aberto.</strong>
        <p />
        <p>
          Você tem certeza que quer baixar o título? Com o caixa fechado, o
          mesmo não será contabilizado em relatório de fechamento de caixa,
          porém, será exibido normalmente no relatório de fluxo de caixa.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <button className="button button-red" onClick={handleClose}>
            Cancelar
          </button>
          <button className="button button-green" onClick={handleSubmit}>
            Baixar o Título
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
