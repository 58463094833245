import { INVOICE_STATUS_DESCRIPTION } from 'utils/constants';

export const NOT_DIGITAL_PAYMENT_FORMS = [
  'Dinheiro',
  'Cheque',
  'Crédito Loja',
  'Sem pagamento',
];

export const DIGITAL_PAYMENT_FORMS = [
  'Cartão de Crédito',
  'Cartão de Débito',
  'Vale Alimentação',
  'Vale Refeição',
  'Vale Presente',
  'Vale Combustível',
  'Boleto Bancário',
  'Depósito Bancário',
  'Pagamento Instantâneo (PIX)',
  'Transferência bancária, Carteira Digital',
  'Programa de fidelidade, Cashback, Crédito Virtual',
];

export const AUTHENTICATION_ERRORS = [
  {
    status: 405,
    error: 'Already logged User.',
  },
];

export const BRANDINGS = [
  {
    id: 1,
    acronym: 'OSD',
    name: 'OS Digital',
  },
  {
    id: 2,
    acronym: 'WM',
    name: 'WorkMotor',
  },
  {
    id: 3,
    acronym: 'Tecnomotor',
    name: 'Tecnomotor',
  },
  {
    id: 4,
    acronym: 'Barros',
    name: 'Barros',
  },
  {
    id: 5,
    acronym: 'PO360',
    name: 'PO360',
  },
  {
    id: 6,
    acronym: 'Furacão',
    name: 'Furacão',
  },
];

export const PLANS = [
  {
    id: 1,
    description: 'Essencial',
    brandingId: 1,
  },
  {
    id: 2,
    description: 'Prime',
    brandingId: 1,
  },
  {
    id: 3,
    description: 'Start',
    brandingId: 1,
  },
  {
    id: 4,
    description: 'Free',
    brandingId: 1,
  },
  {
    id: 5,
    description: 'WorkMotor',
    brandingId: 2,
  },
  {
    id: 6,
    description: 'WorkMotor Plus',
    brandingId: 2,
  },
];

export const INVOICE_STATUS_OPTIONS = [
  { value: '*', label: 'Todos' },
  { value: INVOICE_STATUS_DESCRIPTION.OPEN, label: 'Aberta' },
  {
    value: INVOICE_STATUS_DESCRIPTION.ISSUED,
    label: 'Emitida',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.DISABLED,
    label: 'Inutilizada',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.CANCELED,
    label: 'Cancelada',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.CONTINGENCY,
    label: 'Contingência',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
    label: 'Aberta c/ Erro',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.DENIED,
    label: 'Denegada',
  },
];

export const SERVICE_INVOICE_STATUS_OPTIONS = [
  { value: '*', label: 'Todos' },
  { value: INVOICE_STATUS_DESCRIPTION.OPEN, label: 'Aberta' },
  {
    value: INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
    label: 'Aberta c/ Erro',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.PROCESSING,
    label: 'Processando',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.ISSUED,
    label: 'Emitida',
  },
  {
    value: INVOICE_STATUS_DESCRIPTION.CANCELED,
    label: 'Cancelada',
  },
];

export const PRINTING_TYPES = {
  MOBILE_CHECKLIST: 'mobile-checklist',
};
