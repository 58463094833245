import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import crypto from 'crypto';

import {
  phoneMask,
  maxLength,
  email,
  name,
  descriptionWithAltSign,
} from '../../../components/ToNormalize/ToNormalize';
import DropPhotoContainer from './DropPhotoContainer';
import AlertModal from '../../../../components/AlertModal';
import { cpfMask, cnpjMask } from '../../../../utils/masks';
import RenderField from '../../../../components/RenderField';

const MainData = ({ loadCompany }) => {
  const [isConfirmRemoveLogoModalOpen, setIsConfirmRemoveLogoModalOpen] =
    useState(false);
  const [imageToExclude, setImageToExclude] = useState({});

  const dispatch = useDispatch();
  const { id, Type, typeOriginal, Cpf_Cnpj, Cpf_CnpjOriginal, logo } =
    useSelector((state) => state.form.meusDados.values);

  useEffect(() => {
    if (Type !== typeOriginal) {
      // Quando o tipo pessoa for alterado, irá limpar o form
      dispatch([
        change('meusDados', 'Company_Name', ''),
        change('meusDados', 'Trading_Name', ''),
        change('meusDados', 'Cpf_Cnpj', ''),
        change('meusDados', 'Email', ''),
        change('meusDados', 'Phone', ''),
        change('meusDados', 'Contato', ''),
      ]);
    } else {
      if (id) {
        loadCompany();
      }
    }
  }, [Type]);

  const addImageProcess = (objectURL) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img.height, img.width);
      img.onerror = reject;
      img.src = objectURL;
    });
  };

  const createFileObject = (file, index) => ({
    id: index,
    file: file,
    name: `${crypto.randomBytes(16).toString('hex')}-${file.name}`,
    preview: URL.createObjectURL(file),
    uploaded: false,
  });

  const handleValidateFiles = async (files, quantity, width, height) => {
    if (files.length > quantity) {
      toastr.warning(
        `Selecione somente ${quantity} ${quantity === 1 ? 'imagem' : 'imagens'}`
      );
      return false;
    }

    for (let i = 0; i < quantity; i++) {
      if (files[i].size > 5000000) {
        toastr.warning(
          'Por favor, adicione uma imagem menor, o limite máximo é de 5MB.'
        );

        return false;
      }

      let validateImage = await addImageProcess(
        URL.createObjectURL(files[i])
      ).then((imgHeight, imgWidth) => {
        if (imgWidth < width || imgHeight < height) {
          toastr.warning(
            `Por favor, adicione uma imagem com o tamanho minimo de ${width}x${height}px.`
          );

          return false;
        }

        return true;
      });

      URL.revokeObjectURL(files[i]);

      if (!validateImage) return false;

      return true;
    }
  };

  const handleUploadLogo = async (files) => {
    let validateFiles = await handleValidateFiles(files, 1, 300, 150);

    if (!validateFiles) return;

    const file = createFileObject(files[0], 0);

    // CASO TENHA ALTERADO O LOGO, E NÃO REMOVIDO
    if (!!logo?.uploaded) {
      let imagesToDelete = [];

      imagesToDelete.push(logo);
      await dispatch(change('meusDados', 'imagesToDelete', imagesToDelete));
    }

    dispatch(change('meusDados', 'logo', file));
  };

  const handleRemoveLogo = () => {
    dispatch(change('meusDados', 'logo', {}));
    dispatch(change('meusDados', 'Url_Logo', null));

    if (imageToExclude.uploaded) {
      let imagesToDelete = [];

      imagesToDelete.push(imageToExclude);

      dispatch(change('meusDados', 'imagesToDelete', imagesToDelete));
    }

    setImageToExclude({});
    setIsConfirmRemoveLogoModalOpen(false);
  };

  const handleRemoveLogoAlertModal = (image) => {
    setImageToExclude(image);
    setIsConfirmRemoveLogoModalOpen(true);
  };

  return (
    <>
      <Col className="tipoPessoa" lg={12} md={12} sm={12} xs={12}>
        <Col lg={2} md={3} sm={4} xs={12}>
          <Field
            name="Type"
            label="Tipo de Pessoa: "
            required
            component={RenderField}
            as="select"
            disabled={id && Type === 'Jurídica' && typeOriginal === 'Jurídica'}
          >
            <option value="Jurídica">Jurídica</option>
            <option value="Física">Física</option>
          </Field>
        </Col>
      </Col>
      {Type === 'Jurídica' ? (
        <>
          <Col lg={2} md={3} sm={4} xs={12}>
            <Field
              disabled={
                Cpf_CnpjOriginal !== undefined && Cpf_CnpjOriginal === Cpf_Cnpj
              }
              label="CNPJ"
              required
              name="Cpf_Cnpj"
              component={RenderField}
              {...cnpjMask}
            />
          </Col>

          <Col lg={5} md={6} sm={12} xs={12}>
            <Field
              component={RenderField}
              name="Company_Name"
              label="Razão Social"
              required
              placeholder="Razão Social"
              disabled={false}
              normalize={(value) =>
                maxLength(descriptionWithAltSign(value), 50)
              }
            />
          </Col>

          <Col lg={3} md={3} sm={12} xs={12}>
            <Field
              name="Trading_Name"
              label="Nome Fantasia"
              required
              placeholder="Nome Fantasia"
              component={RenderField}
              normalize={(value) =>
                maxLength(descriptionWithAltSign(value), 50)
              }
            />
          </Col>

          <Col lg={2} md={2} sm={3} xs={12} style={{ marginTop: '-80px' }}>
            <label>
              <small>Logo (300x150px) </small>
            </label>
            <div style={{ width: 'fit-content' }}>
              <DropPhotoContainer
                image={logo}
                style={{ width: 'fit-content' }}
                onRemove={() => handleRemoveLogoAlertModal(logo)}
                onUpload={handleUploadLogo}
              />
            </div>
          </Col>

          <Col lg={3} md={3} sm={12} xs={12}>
            <Field
              name="Email"
              label="E-mail"
              required
              placeholder="E-mail"
              component={RenderField}
              normalize={email}
              style={{
                backgroundColor: '#FFF',
              }}
            />
          </Col>

          <Col lg={2} md={2} sm={12} xs={12}>
            <Field
              name="Phone"
              label="Telefone"
              required
              component={RenderField}
              normalize={phoneMask}
            />
          </Col>

          <Col lg={2}>
            <Field
              name="Contato"
              label="Responsável"
              required
              component={RenderField}
              normalize={(value) => maxLength(name(value), 50)}
            />
          </Col>
        </>
      ) : (
        <>
          <Col lg={2} md={3} sm={4} xs={12}>
            <Field
              name="Cpf_Cnpj"
              disabled={
                Cpf_CnpjOriginal !== undefined && Cpf_CnpjOriginal === Cpf_Cnpj
              }
              label="CPF"
              required
              component={RenderField}
              {...cpfMask}
            />
          </Col>
          <Col lg={4} md={5} sm={12} xs={12}>
            <Field
              name="Company_Name"
              label="Nome Completo"
              required
              placeholder="Nome Completo"
              component={RenderField}
              normalize={(value) => maxLength(name(value), 50)}
            />
          </Col>

          <Col lg={4} md={2} sm={12} xs={12}>
            <Field
              name="Email"
              label="E-mail"
              required
              placeholder="E-mail"
              component={RenderField}
              normalize={email}
              style={{
                backgroundColor: '#FFF',
              }}
            />
          </Col>

          <Col lg={2} md={2} sm={3} xs={12} style={{ marginTop: '-80px' }}>
            <label>
              <small>Logo (300x150px) </small>
            </label>
            <div style={{ width: 'fit-content' }}>
              <DropPhotoContainer
                image={logo}
                style={{ width: 'fit-content' }}
                onRemove={() => handleRemoveLogoAlertModal(logo)}
                onUpload={handleUploadLogo}
              />
            </div>
          </Col>

          <Col xs={12} sm={12} md={2} lg={2}>
            <Field
              name="Phone"
              label="Telefone"
              required
              component={RenderField}
              normalize={phoneMask}
            />
          </Col>
        </>
      )}

      <AlertModal
        message="Deseja realmente excluir a logo?"
        show={isConfirmRemoveLogoModalOpen}
        onCancel={() => setIsConfirmRemoveLogoModalOpen(false)}
        onHide={() => setIsConfirmRemoveLogoModalOpen(false)}
        onSubmit={handleRemoveLogo}
      />
    </>
  );
};

export default MainData;
