import React, { useState, useEffect } from 'react';
import {
  faCalendarCheck,
  faCalendarTimes,
  faSync,
  faReceipt,
  faUserCog,
  faBoxOpen,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import { format, differenceInMonths, isBefore, set } from 'date-fns';

import NewSignatureModal from './NewSignature';
import QuantityUserModal from './QuantityUsersModal';
import AlertModal from '../../../../components/AlertModal';
import ModulesModal from './ModulesModal';
import ProductBaseModal from './ProductsBase';
import ClientActionButton from '../../../components/ClientActionButton';
import ChangeDataModal from './ChangeData';
import ChangeCompanyCpfCnpjModal from './ChangeData/ChangeCompanyDocument';
import ChangeUserEmailModal from './ChangeData/ChangeUserEmail';
import UpdateCpfCnpjConfirmModal from './ChangeData/ChangeCompanyDocumentConfirmModal';
import ChangeCompanyUserEmail from './ChangeData/ChangeUserEmail';

import constants from '../../../../utils/constants';
import { useAdminAuth } from '../../../../contexts/adminAuth';
import { useSelector } from 'react-redux';

import signaturesRepository from 'repositories/Signatures';
import superlogicaRepository from 'repositories/Superlogica';
import companiesRepository from 'repositories/Companies';
import phonesRepository from 'repositories/Phones';
import addressesRepository from 'repositories/Addresses';
import signatureRepository from 'repositories/Signatures';
import mySuiteRepository from 'repositories/MySuite';
import profilesRepository from 'repositories/Profiles';
import prismaboxRepository from 'repositories/PrismaBox';
import companyRepository from 'repositories/Companies';
import CleanTaxDataCompanyModal from './ChangeData/CleanTaxDataCompany';
import plansRepository from 'repositories/Plans';

const ClientActions = ({ client, reload, setReload }) => {
  const [companyToChange, setCompanyToChange] = useState({});

  const [showChangeDataModalOpen, setShowChangeDataModalOpen] = useState(false);
  const [showChangeCpfCnpjModal, setShowChangeCpfCnpjModal] = useState(false);
  const [showUpdateCpfCnpjConfirmModal, setShowUpdateCpfCnpjConfirmModal] =
    useState(false);
  const [showChangeUserEmailModal, setShowChangeUserEmailModal] =
    useState(false);

  const [isNewSignatureModalOpen, setIsNewSignatureModalOpen] = useState(false);
  const [isChangeQuantityUserModalOpen, setIsChangeQuantityUserModalOpen] =
    useState(false);
  const [isCancelSignatureModalOpen, setIsCancelSignatureModalOpen] =
    useState(false);
  const [isCancelSignatureLoading, setIsCancelSignatureLoading] =
    useState(false);
  const [isModulesModalOpen, setIsModulesModalOpen] = useState(false);

  const [isProductBaseModalOpen, setIsProductBaseModalOpen] = useState(false);

  const [isSignatureDegustation, setIsSignatureDegustation] = useState();
  const [isSignatureActive, setIsSignatureActive] = useState();
  const [loading, setLoading] = useState(false);

  const { adminRoleId } = useAdminAuth();

  const isAdm = adminRoleId === constants.ADMIN_ROLES.ADMINISTRATOR;

  const { meusDados } = useSelector((state) => state.form);

  const signatureStatus = meusDados?.values?.signatureStatus;
  const lastSignatureId = meusDados?.values?.lastSignatureId;
  const endDateOSDig = meusDados?.values?.signatureEndDate;
  const superLogicaId = meusDados?.values?.superLogicaId;
  const [showCleanTaxDataModal, setShowCleanTaxDataModal] = useState(false);
  const [cleanTaxData, setCleanTaxData] = useState(null);
  const [cleanChangeCpfCNpj, setCleanChangeCpfCnpj] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    setIsSignatureDegustation(
      signatureStatus === constants.SIGNATURE_STATUS.DEGUSTATION
    );
    setIsSignatureActive(signatureStatus === constants.SIGNATURE_STATUS.ACTIVE);
  }, [reload, signatureStatus]);

  function handleSubmitNewSignature() {
    setIsNewSignatureModalOpen(false);
    setReload((reload) => !reload);
  }

  async function handleSubmitCancelSignature() {
    setIsCancelSignatureLoading(true);

    try {
      await signaturesRepository.update(lastSignatureId, {
        SignatureStatus_id: constants.SIGNATURE_STATUS.CANCELED,
        End_Date: new Date(),
      });

      setIsCancelSignatureModalOpen(false);
      setReload((reload) => !reload);

      toastr.success('Assinatura cancelada com sucesso.');
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao cancelar a assinatura. Por favor, tente novamente'
      );
    }

    setIsCancelSignatureLoading(false);
  }

  async function updateCompanyWithSuperlogicaData() {
    setLoading(true);
    let isCompanyRegisteredOnSuperlogica = false;

    try {
      const { data } = await superlogicaRepository.getClientSignature(
        client.id
      );

      if (data) isCompanyRegisteredOnSuperlogica = true;

      const superlogicaLastSignature = data[0];
      const {
        dt_contrato_plc,
        dt_cancelamento_plc,
        st_nome_pla,
        st_nome_gpl,
        fl_primeiropag_plc,
        fl_primeiropagnotificado_plc,
        sacado,
        id_sacado_sac,
      } = superlogicaLastSignature;

      if (client.Cpf_Cnpj !== sacado[0].st_cgc_sac) {
        toastr.warning(
          'CPF/CNPJ diverge do cadastro na Superlógica',
          'O CPF/CNPJ cadastrado na Superlógica não corresponde ao CPF/CNPJ cadastrado no sistema. Por favor, verifique e tente novamente.'
        );
        return;
      }

      if (Number(superLogicaId) !== Number(id_sacado_sac)) {
        toastr.warning(
          'ID diverge do cadastro na Superlógica',
          'O ID cadastrado na Superlógica não corresponde ao ID cadastrado para essa empresa. Por favor, verifique e tente novamente.'
        );
        return;
      }

      if (!dt_cancelamento_plc) {
        toastr.warning(
          'Data da vigência da assinatura obrigatória',
          'Não identificamos a data final da vigência do plano do cliente. Por favor, verifique a assinatura dele na superlógica.'
        );
        return;
      }

      const { dt_congelamento_sac, dt_desativacao_sac } = sacado[0];

      const isSignatureBlocked = !!dt_congelamento_sac;
      const isSignatureCanceled = !!dt_desativacao_sac;

      const isPaymentCompleted =
        fl_primeiropag_plc === '1' && fl_primeiropagnotificado_plc === '1';

      const clientLastSignatureOSDig = client.lastSignature;
      const clientPlanNameOSDig = client.lastSignature?.Plans?.Description;

      const endDateSuperlogicaDDMMYYYY = format(
        new Date(dt_cancelamento_plc),
        'dd/MM/yyyy'
      );

      const isNewPlanFiscal = st_nome_pla.includes('FISCAL');
      const lastPlanIsFiscal = clientPlanNameOSDig?.includes('FISCAL');
      const isPlanSameLevel = st_nome_pla.includes(clientPlanNameOSDig);

      const isPlanDifferent = (() => {
        switch (true) {
          case !lastPlanIsFiscal && isNewPlanFiscal:
            return true;
          case lastPlanIsFiscal && !isNewPlanFiscal:
            return true;
          case !isPlanSameLevel:
            return true;
          default:
            return false;
        }
      })();

      if (
        (isPaymentCompleted && isPlanDifferent) ||
        endDateOSDig !== endDateSuperlogicaDDMMYYYY ||
        isSignatureBlocked ||
        (!isSignatureBlocked &&
          clientLastSignatureOSDig.SignatureStatus_id ===
            constants.SIGNATURE_STATUS.BLOCKED) ||
        (!!isSignatureCanceled &&
          clientLastSignatureOSDig.SignatureStatus_id !==
            constants.SIGNATURE_STATUS.CANCELED) ||
        (!isSignatureCanceled &&
          clientLastSignatureOSDig.SignatureStatus_id ===
            constants.SIGNATURE_STATUS.CANCELED)
      ) {
        await updateCompanySignature(
          st_nome_pla,
          dt_contrato_plc,
          dt_cancelamento_plc,
          clientLastSignatureOSDig,
          isSignatureBlocked,
          isSignatureCanceled,
          st_nome_gpl,
          id_sacado_sac
        );

        // mySuiteRepository.createUpdateCompanyMySuite(client.id);
      }

      if (st_nome_pla.includes('Prime') || st_nome_pla === 'Top') {
        try {
          const { data: existPb1 } = await prismaboxRepository.index({
            clientId: client.id,
            description: 'Box 1',
          });

          if (existPb1.length === 0) {
            await prismaboxRepository.create({
              clientId: client.id,
              description: 'Box 1',
              status: true,
            });
          }
        } catch (err) {
          throw err;
        }
      }

      setReload((reload) => !reload);
      toastr.success('Status atualizado com sucesso.');
    } catch (err) {
      console.error(err);

      if (!isCompanyRegisteredOnSuperlogica) {
        toastr.warning(
          'Ocorreu um erro ao buscar os dados na Superlógica. Verifique se a empresa tem assinatura e identificador vinculados.'
        );
      } else {
        toastr.error('Ocorreu um erro ao atualizar a empresa.');
      }
    } finally {
      setLoading(false);
    }
  }

  async function updateCompanySignature(
    planName,
    startDate,
    endDate,
    clientLastSignatureOSDig,
    isSignatureBlocked,
    isSignatureCanceled,
    st_nome_gpl,
    id_sacado_sac
  ) {
    try {
      if (isSignatureBlocked) {
        return await signaturesRepository.update(clientLastSignatureOSDig.id, {
          SignatureStatus_id: constants.SIGNATURE_STATUS.BLOCKED,
        });
      } else if (
        clientLastSignatureOSDig.SignatureStatus_id ===
        constants.SIGNATURE_STATUS.BLOCKED
      ) {
        return await signaturesRepository.update(clientLastSignatureOSDig.id, {
          SignatureStatus_id: constants.SIGNATURE_STATUS.ACTIVE,
        });
      }

      if (isSignatureCanceled) {
        return await signaturesRepository.update(clientLastSignatureOSDig.id, {
          SignatureStatus_id: constants.SIGNATURE_STATUS.CANCELED,
        });
      } else if (
        clientLastSignatureOSDig.SignatureStatus_id ===
        constants.SIGNATURE_STATUS.CANCELED
      ) {
        return await signaturesRepository.update(clientLastSignatureOSDig.id, {
          SignatureStatus_id: constants.SIGNATURE_STATUS.ACTIVE,
        });
      }

      const startDateFormated = new Date(`${startDate} 00:00:00`);
      const endDateFormated = new Date(`${endDate} 23:59:59`);

      const isNewPlanFiscal = planName?.includes('FISCAL');
      const lastPlanIsFiscal =
        clientLastSignatureOSDig?.Plans?.Description?.includes('FISCAL');
      const isPlanSameLevel = planName?.includes(
        clientLastSignatureOSDig?.Plans?.Description
      );

      if (
        (isNewPlanFiscal && lastPlanIsFiscal && isPlanSameLevel) ||
        (!isNewPlanFiscal && !lastPlanIsFiscal && isPlanSameLevel)
      ) {
        const { User_id, Company_id, Plan_id, signaturePeriod, usersQuantity } =
          clientLastSignatureOSDig;

        const todayDate = new Date();

        const planInformation = await plansRepository.getById(Plan_id);

        const signature = {
          User_id,
          Company_id,
          Plan_id,
          SignatureStatus_id: isBefore(endDateFormated, todayDate)
            ? constants.SIGNATURE_STATUS.CANCELED
            : constants.SIGNATURE_STATUS.ACTIVE,
          signaturePeriod,
          usersQuantity:
            planInformation && planInformation.data
              ? planInformation.data.usersQuantity
              : 0,
          startDate: startDateFormated,
          endDate: endDateFormated,
        };

        const createdSignature = await signatureRepository.create(signature);
        return signatureRepository.cancelOldSignatures(
          client.id,
          createdSignature.id
        );
      }

      let planId;

      if (st_nome_gpl === 'WHITE LABEL') {
        const clientSuperLogicaInformation =
          await superlogicaRepository.getClientBySacadoId(id_sacado_sac);
        const clientSuperlogicaGroup =
          clientSuperLogicaInformation?.data?.sacado_grupo[0]?.st_nome_grp;

        const isFiscal = planName.toUpperCase().includes('FISCAL');

        if (clientSuperlogicaGroup.toUpperCase().includes('TECNOMOTOR')) {
          switch (true) {
            case planName.includes('START'):
              planId = 12;
              break;
            case planName.includes('ESSENCIAL'):
              planId = isFiscal ? 26 : 10;
              break;
            case planName.includes('PRIME'):
              planId = isFiscal ? 27 : 11;
              break;
            case planName.includes('ENTERPRISE'):
              planId = isFiscal ? 25 : 13;
              break;
            default:
              planId = 14;
              break;
          }
        }

        if (clientSuperlogicaGroup.toUpperCase().includes('BARROS')) {
          switch (true) {
            case planName.includes('START'):
              planId = 17;
              break;
            case planName.includes('ESSENCIAL'):
              planId = isFiscal ? 29 : 15;
              break;
            case planName.includes('PRIME'):
              planId = isFiscal ? 30 : 16;
              break;
            case planName.includes('ENTERPRISE'):
              planId = isFiscal ? 28 : 19;
              break;
            default:
              planId = 18;
              break;
          }
        }

        if (clientSuperlogicaGroup.toUpperCase().includes('360')) {
          switch (true) {
            case planName.includes('START'):
              planId = 22;
              break;
            case planName.includes('ESSENCIAL'):
              planId = isFiscal ? 32 : 20;
              break;
            case planName.includes('PRIME'):
              planId = isFiscal ? 33 : 21;
              break;
            case planName.includes('ENTERPRISE'):
              planId = isFiscal ? 31 : 24;
              break;
            default:
              planId = 23;
              break;
          }
        }
      } else if (planName.includes('FISCAL')) {
        if (planName.includes('Prime')) {
          planId = 9;
        } else if (planName.includes('Essencial')) {
          planId = 8;
        } else if (planName.includes('WM')) {
          planId = 7;
        }
      } else {
        if (planName === 'Básico' || planName.includes('Essencial')) {
          planId = 1;
        } else if (planName === 'Top' || planName.includes('Prime')) {
          planId = 2;
        } else if (planName.includes('Start')) {
          planId = 3;
        } else if (planName.includes('WM FREE')) {
          planId = 5;
        } else if (planName.includes('Free')) {
          planId = 4;
        } else if (planName.includes('WM')) {
          planId = 6;
        }
      }

      const { data: adminProfile } = await profilesRepository.getAdminProfile();

      const adminCompany = client.Users.find(
        (user) => user.profileId === adminProfile.id
      );
      const userId = adminCompany.id;

      const informationsPlan = await plansRepository.getById(planId);

      const periodInMonths = differenceInMonths(
        endDateFormated,
        startDateFormated
      );

      let signaturePeriod;

      //Aproximações feitas por falta de campo período na Superlógica
      if (periodInMonths === 1) {
        signaturePeriod = 'Mensal';
      } else if (periodInMonths >= 2 && periodInMonths < 5) {
        signaturePeriod = 'Trimestral';
      } else if (periodInMonths >= 5 && periodInMonths < 11) {
        signaturePeriod = 'Semestral';
      } else if (periodInMonths >= 11) {
        signaturePeriod = 'Anual';
      }

      const signature = {
        User_id: userId,
        Company_id: client.id,
        Plan_id: planId,
        SignatureStatus_id: 2,
        startDate: startDateFormated,
        endDate: endDateFormated,
        signaturePeriod,
        usersQuantity:
          informationsPlan && informationsPlan.data
            ? informationsPlan.data.Users_Quantity
            : 0,
      };

      const createdSignature = await signatureRepository.create(signature);
      return signatureRepository.cancelOldSignatures(
        client.id,
        createdSignature.id
      );
    } catch (err) {
      console.error(err);
    }
  }

  function selectChangeDataOption(option) {
    setShowChangeDataModalOpen(false);

    if (option === 'cpf-cnpj') {
      setShowChangeCpfCnpjModal(true);
    } else if (option === 'user-email') {
      setShowChangeUserEmailModal(true);
    }
  }

  // CPF / CNPJ change
  async function handleSubmitChangeCpfCnpj(newCpfCnpj) {
    setLoading(true);
    try {
      if (!newCpfCnpj) {
        return toastr.warning(
          'Documento nulo',
          'Insira um documento válido para realizar a atualização'
        );
      }

      const cpfCnpjUnformated = newCpfCnpj.replaceAll(/\D/g, '');

      if (cpfCnpjUnformated.length !== client.Cpf_Cnpj.length) {
        return toastr.warning(
          'Alteração inválida',
          'Não é possível alterar um CNPJ para CPF ou vice-versa. A informação deverá ser do mesmo tipo. Verifique e tente novamente'
        );
      }

      const documentAlreadyRegistered = await companyRepository.getByCpfCnpj(
        cpfCnpjUnformated,
        client.id
      );

      setCleanChangeCpfCnpj(cpfCnpjUnformated);
      setCleanTaxData(documentAlreadyRegistered.taxCountData);

      if (documentAlreadyRegistered.id) {
        setCompanyToChange(documentAlreadyRegistered);
        setShowUpdateCpfCnpjConfirmModal(true);
      } else {
        setShowCleanTaxDataModal(true);
      }
    } catch (err) {
      setShowChangeCpfCnpjModal(false);
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao consultar se este CPF/CNPJ ja está em uso, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function updateCompanyCpfCnpj(newCpfCnpj) {
    setLoading(true);
    try {
      await companyRepository.updateCompanyCpfCnpj({
        companyId: client.id,
        cpfCnpj: newCpfCnpj,
        forceUpdate: forceUpdate ? true : false,
      });

      setReload((reload) => !reload);

      return toastr.success(
        'Sucesso',
        `CPF/CNPJ da empresa ${client.Company_Name} alterado com sucesso`
      );
    } catch (err) {
      return toastr.error(
        'Erro',
        'Ocorreu um erro ao atualizar o CPF/CNPJ da empresa, tente novamente'
      );
    } finally {
      setShowUpdateCpfCnpjConfirmModal(false);
      setShowChangeCpfCnpjModal(false);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="admin-clients-actions">
        <ClientActionButton
          title="Troca Dados"
          backgroundColor="#3f729b"
          icon={faUserEdit}
          onClick={() => setShowChangeDataModalOpen(true)}
        />

        {isAdm ? (
          <ClientActionButton
            title="Nova Assinatura"
            backgroundColor="#5cb85c"
            icon={faCalendarCheck}
            onClick={() => setIsNewSignatureModalOpen(true)}
          />
        ) : (
          ''
        )}

        {isAdm && isSignatureActive ? (
          <ClientActionButton
            title="Cancelar Assinatura"
            backgroundColor="#d9534f"
            icon={faCalendarTimes}
            onClick={() => setIsCancelSignatureModalOpen(true)}
          />
        ) : (
          ''
        )}

        {isSignatureActive ? (
          <ClientActionButton
            title="Base de Produtos"
            backgroundColor="#5bc0de"
            icon={faBoxOpen}
            onClick={() => setIsProductBaseModalOpen(true)}
          />
        ) : (
          ''
        )}

        {isSignatureActive ? (
          <ClientActionButton
            title="Módulos"
            backgroundColor="#f0ad4e"
            icon={faReceipt}
            onClick={() => setIsModulesModalOpen(true)}
          />
        ) : (
          ''
        )}

        {isSignatureDegustation || isSignatureActive ? (
          <ClientActionButton
            title="Usuários"
            backgroundColor="#1d7391"
            icon={faUserCog}
            onClick={() => setIsChangeQuantityUserModalOpen(true)}
          />
        ) : (
          ''
        )}

        <ClientActionButton
          title="Atualizar Status"
          backgroundColor="#428bca"
          icon={faSync}
          onClick={updateCompanyWithSuperlogicaData}
          loading={loading}
        />
      </div>

      {/* Change company CPF/CNPJ */}
      {showChangeDataModalOpen && (
        <ChangeDataModal
          company={client}
          onCancel={() => setShowChangeDataModalOpen(false)}
          selectOption={selectChangeDataOption}
        />
      )}

      {showChangeCpfCnpjModal && (
        <ChangeCompanyCpfCnpjModal
          companyCpfCnpj={client.Cpf_Cnpj}
          handleSubmit={handleSubmitChangeCpfCnpj}
          onCancel={() => setShowChangeCpfCnpjModal(false)}
        />
      )}

      {showUpdateCpfCnpjConfirmModal && (
        <UpdateCpfCnpjConfirmModal
          company={companyToChange}
          actualClientId={client.id}
          onCancel={() => setShowUpdateCpfCnpjConfirmModal(false)}
          handleSubmit={() => {
            setForceUpdate(true);
            setShowUpdateCpfCnpjConfirmModal(false);
            setShowCleanTaxDataModal(true);
          }}
        />
      )}

      {/* Change company user email */}
      {showChangeUserEmailModal && (
        <ChangeCompanyUserEmail
          company={client}
          onCancel={() => setShowChangeUserEmailModal(false)}
        />
      )}

      {isNewSignatureModalOpen && (
        <NewSignatureModal
          onSuccess={handleSubmitNewSignature}
          onCancel={() => setIsNewSignatureModalOpen(false)}
          onHide={() => setIsNewSignatureModalOpen(false)}
          company={client}
        />
      )}

      {isChangeQuantityUserModalOpen && (
        <QuantityUserModal
          onCancel={() => setIsChangeQuantityUserModalOpen(false)}
          client={client}
        />
      )}

      {isModulesModalOpen && (
        <ModulesModal
          onCancel={() => setIsModulesModalOpen(false)}
          client={client}
        />
      )}

      {isProductBaseModalOpen && (
        <ProductBaseModal
          onCancel={() => setIsProductBaseModalOpen(false)}
          client={client}
        />
      )}

      <AlertModal
        title="Cancelamento de Assinatura"
        message="Deseja efetuar o cancelamento da assinatura desse cliente? Se cancelado, deverá ser efetuado uma nova assinatura posteriormente"
        show={isCancelSignatureModalOpen}
        onCancel={() => setIsCancelSignatureModalOpen(false)}
        onHide={() => setIsCancelSignatureModalOpen(false)}
        onSubmit={handleSubmitCancelSignature}
        loading={isCancelSignatureLoading}
      />
      {showCleanTaxDataModal && (
        <CleanTaxDataCompanyModal
          onCancel={() => setShowCleanTaxDataModal(false)}
          onConfirm={() => {
            setShowCleanTaxDataModal(false);
            updateCompanyCpfCnpj(cleanChangeCpfCNpj);
          }}
          cleanTaxData={cleanTaxData}
        />
      )}
    </>
  );
};

export default ClientActions;
