import { ideaWallAPi } from 'v2/services/ideaWall';
import api from '../services/api';

const BASE_URL = 'ideas-wall';

const getSearchIdeasWall = async (filters = {}) => {
  try {
    const response = await api.get(`/${BASE_URL}`, {
      params: {
        page: filters.page ?? 1,
        limit: filters.limit ?? 10,
        ...Object.fromEntries(
          Object.entries(filters).filter(
            ([_, value]) =>
              value !== undefined && value !== null && value !== ''
          )
        ),
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar as ideias:', error);
    throw new Error('Erro ao buscar as ideias');
  }
};

const updateIdeaWall = async (postId, body) => {
  try {
    const response = await api.put(`/${BASE_URL}/post/${postId}`, body);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar Idea Wall:', error);
    throw error;
  }
};
const getIdeasWallCategories = async () => {
  try {
    const response = await ideaWallAPi.get(`/posts/categories`);

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar as categorias:', error);
    throw new Error('Erro ao buscar as categorias');
  }
};
const getIdeasWallSubCategories = async (categoryIds) => {
  try {
    const response = await ideaWallAPi.get(`/posts/subcategories`, {
      params: {
        categoryIds,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar as sub categorias:', error);
    throw new Error('Erro ao buscar as sub categoriass');
  }
};
const getIdeasWallStatus = async () => {
  try {
    const response = await ideaWallAPi.get(`/posts/status`);

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar Status:', error);
    throw new Error('Erro ao buscar Status');
  }
};

export const IdeasWallRepository = {
  getSearchIdeasWall,
  getIdeasWallCategories,
  getIdeasWallSubCategories,
  getIdeasWallStatus,
  updateIdeaWall,
};
