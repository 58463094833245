import api from '../services/api';
import config from '../config';

const getAll = async () => {
  const response = await api.get(config.endpoint + 'plans');
  return response.data;
};

const getById = async (id) => {
  const response = await api.get(`plans/${id}`);
  return response.data;
};

const plansRepository = {
  getAll,
  getById,
};

export default plansRepository;
