import React from 'react';
import { useRecoilState } from 'recoil';

import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import { InactiveModuleModal } from 'client/components/InactiveModuleModal';

import { useBlockModals } from 'v2/hooks/useBlockModals';
import FiscalModuleInactiveModal from 'client/components/FiscalModuleInactiveModal';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import constants from 'utils/constants';
import { SegmentsBlockedModal } from 'client/components/SegmentsBlockedModal';
import { useAuth } from 'contexts/auth';
import { CrmBlockedModuleWithoutPlanModal } from '../../../../client/components/CrmBlockedModuleWithoutPlanModal';
import { CrmBlockedModuleWithPlanModal } from 'client/components/CrmBlockedModuleWithPlanModal';
import { GenericBlockedModuleModal } from 'client/components/GenericBlockedModuleModal';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { YardManagementBlockedModuleWithoutPlanModal } from 'client/components/YardManagementBlockedModuleWithoutPlanModal';

export function GlobalBlockModals() {
  const {
    isFeatureNotAvailableModalOpen,
    setIsFeatureNotAvailableModalOpen,

    isModuleInactiveModalOpen,
    setIsModuleInactiveModalOpen,

    currentInactiveModule,
    currentInactiveFiscalModule,
    currentInactiveCrmModule,
    currentInactiveVideoModule,

    isVideoModalOpen,
    setIsVideoModalOpen,

    isFiscalModuleInactiveModalOpen,
    setIsFiscalModuleInactiveModalOpen,

    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,

    isSegmentsBlockedModalOpen,
    setIsSegmentsBlockedModalOpen,

    isCrmBlockedModuleWithoutPlanModalOpen,
    setIsCrmBlockedModuleWithoutPlanModalOpen,

    isCrmBlockedModuleWithPlanModalOpen,
    setIsCrmBlockedModuleWithPlanModalOpen,

    isYardManagementBlockedModuleWithoutPlanModalOpen,
    setIsYardManagementBlockedModuleWithoutPlanModalOpen,
  } = useBlockModals();

  const { companyId } = useAuth();
  const { isPlanFree } = usePlanSignatureContext();

  const handleNavigateToMyCompany = async () => {
    setIsSegmentsBlockedModalOpen(false);

    window.open(
      window.location.origin + `/client/${constants.ROUTES.COMPANY_DETAILS}`,
      '_blank'
    );
  };

  return (
    <>
      <UnavailableFeatureModal
        show={isFiscalModuleInactivePlanVariationModalOpen}
        onHide={() => setIsFiscalModuleInactivePlanVariationModalOpen(false)}
        unavailableFeatureText={
          `O plano ${
            isPlanFree ? 'free' : 'start'
          } não possui emissão de documento fiscal. ` +
          'Entre em contato com o nosso comercial e solicite a troca de plano e o módulo fiscal.'
        }
      />

      <UnavailableFeatureModal
        show={isFeatureNotAvailableModalOpen}
        onHide={() => setIsFeatureNotAvailableModalOpen(false)}
        unavailableFeatureText={
          'Para ter acesso a funcionalidade você precisa solicitar o upgrade do seu plano, para isso, ' +
          'clique no botão solicitar troca de plano para conversar com um dos nossos consultores comerciais.'
        }
      />

      <InactiveModuleModal
        show={isModuleInactiveModalOpen}
        onHide={() => setIsModuleInactiveModalOpen(false)}
        module={currentInactiveModule}
      />

      <FiscalModuleInactiveModal
        show={isFiscalModuleInactiveModalOpen}
        onHide={() => setIsFiscalModuleInactiveModalOpen(false)}
        module={currentInactiveFiscalModule}
      />

      <SegmentsBlockedModal
        companyId={companyId}
        show={isSegmentsBlockedModalOpen}
        onHide={() => setIsSegmentsBlockedModalOpen(false)}
        onSubmit={() => handleNavigateToMyCompany()}
      />

      <CrmBlockedModuleWithoutPlanModal
        show={isCrmBlockedModuleWithoutPlanModalOpen}
        module={currentInactiveCrmModule}
        onHide={() => setIsCrmBlockedModuleWithoutPlanModalOpen(false)}
        onSubmit={() => handleNavigateToMyCompany()}
      />

      <CrmBlockedModuleWithPlanModal
        show={isCrmBlockedModuleWithPlanModalOpen}
        module={currentInactiveCrmModule}
        onHide={() => setIsCrmBlockedModuleWithPlanModalOpen(false)}
        onSubmit={() => handleNavigateToMyCompany()}
      />

      <GenericBlockedModuleModal
        show={isVideoModalOpen}
        subTitle={'Módulo de importação de vídeo na venda não habilitado.'}
        message={`
          Para utilizar o módulo de ${
            currentInactiveVideoModule.description
          } na venda é necessário contratar o módulo. Com isso, será adicionado o valor de ${currency(
          currentInactiveVideoModule.price
        )} na sua mensalidade.
                `}
        module={currentInactiveVideoModule}
        onHide={() => setIsVideoModalOpen(false)}
        onSubmit={() => handleNavigateToMyCompany()}
      />
      <YardManagementBlockedModuleWithoutPlanModal
        show={isYardManagementBlockedModuleWithoutPlanModalOpen}
        module={currentInactiveCrmModule}
        onHide={() =>
          setIsYardManagementBlockedModuleWithoutPlanModalOpen(false)
        }
        onSubmit={() => handleNavigateToMyCompany()}
      />
    </>
  );
}
