import React, { useEffect, useState } from 'react';

import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { toastr } from 'react-redux-toastr';

import EmployeesRepository from 'repositories/Employees';
import { useAuth } from 'contexts/auth';
import { Modal } from 'react-bootstrap';

import { useForm, useFieldArray } from 'react-hook-form';

export default function SalesEmployeesSelectModal({
  open,
  handleClose,
  onSubmit,
  selectedEmployees,
  disabledSubmit,
  isMechanic = true,
}) {
  const [employees, setEmployees] = useState([]);
  const [allEmployeesSelected, setAllEmployeesSelected] = useState(false);

  const { companyId } = useAuth();

  const { control, watch, getValues, register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const {
    fields: employeesFields,
    append: employeesAppend,
    replace: employeesReplace,
  } = useFieldArray({
    control,
    name: 'employees',
  });

  const watchedEmployees = watch('employees');

  function handleSelectAllEmployees() {
    const selectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: true,
    }));

    employeesReplace(selectedEmployees);
  }

  function handleUnselectAllEmployees() {
    const unselectedEmployees = employeesFields.map((field) => ({
      ...field,
      selected: false,
    }));

    employeesReplace(unselectedEmployees);
  }

  function formatDataToSubmit(data) {
    const selectedEmployees = data.employees.filter((item) => item.selected);
    const selectedEmployeesIds = selectedEmployees.map(
      (item) => item.employeeId
    );

    onSubmit(selectedEmployeesIds);
    handleClose();
  }

  async function loadEmployees() {
    try {
      setLoading(true);

      const { data } =
        await EmployeesRepository.getEmployeesThatPerformServicesOrSellProducts(
          companyId,
          {
            performService: 1,
          }
        );

      const employeesActive = data.filter((item) => item.isActive);

      const serializedEmployees = employeesActive.map((item) => ({
        id: item.id,
        name: item.name,
        selected: selectedEmployees.includes(item.id) ? true : false,
      }));

      serializedEmployees.forEach((item) => {
        employeesAppend({
          employeeId: item.id,
          selected: item.selected,
        });
      });

      setEmployees(serializedEmployees);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar funcionários',
        'Tente novamente. Caso persista, contate o suporte!'
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadEmployees();
  }, []);

  useEffect(() => {
    const allEmployeesLength = employees.length;
    if (allEmployeesLength > 0) {
      const selectedEmployeesLength = watch('employees').filter(
        (item) => item.selected
      ).length;

      if (selectedEmployeesLength === allEmployeesLength) {
        setAllEmployeesSelected(true);
      } else {
        setAllEmployeesSelected(false);
      }
    }
  }, [watchedEmployees]);

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <strong>
            {isMechanic ? 'Mecânicos Responsáveis' : 'Técnicos Responsáveis'}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            padding: '10px',
            marginTop: 0,
          }}
        >
          <div>
            {!disabledSubmit && (
              <>
                <span>Selecione o funcionário:</span>
                <div style={{ textAlign: 'right' }}>
                  <a
                    style={{
                      textDecoration: 'underline',
                      textAlign: 'right',
                      color: '#176CF7',
                      cursor: 'pointer',
                    }}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      if (!allEmployeesSelected) {
                        handleSelectAllEmployees();
                      } else {
                        handleUnselectAllEmployees();
                      }
                    }}
                  >
                    {!allEmployeesSelected ? 'Selecionar ' : 'Remover '} todos
                  </a>
                </div>
              </>
            )}
            <ReactTable
              data={employees}
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                height: '203px',
                fontSize: '14px',
                width: '100%',
              }}
              columns={[
                {
                  Header: 'Nome',
                  accessor: 'name',
                  className: 'texto',
                  resizable: false,
                },
                {
                  Header: ' ',
                  accessor: 'id',
                  className: 'texto',
                  resizable: false,
                  width: 70,
                  Cell: (props) => {
                    return (
                      <>
                        <input
                          type="checkbox"
                          id={employeesFields[props.index].id}
                          disabled={disabledSubmit}
                          className="checkbox-input bulk-deletion-table"
                          {...register(`employees.${props.index}.selected`)}
                        />
                      </>
                    );
                  },
                },
              ]}
              showPagination={false}
              noDataText="Nenhum funcionário encontrado"
              loading={loading}
              loadingText="Carregando..."
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" name="Voltar" onClick={handleClose} fill>
          Voltar
        </Button>
        {!disabledSubmit && (
          <Button
            bsStyle="info"
            fill
            onClick={handleSubmit(formatDataToSubmit)}
          >
            Adicionar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
