import React from 'react';

import { InputLabel } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { useYardManagement } from '../hooks/useYardManagement';
import { InputSelectDefault } from 'v2/components/Input';
import { useFormContext } from 'react-hook-form';

export function FiltersYardManagement() {
  const { watch, setValue, register } = useFormContext();
  const { viewModes } = useYardManagement();

  const model = watch('model');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2.5rem',
        gap: '10px',
        alignItems: 'flex-end',
      }}
    >
      <InputContainer gap="0">
        <InputLabel style={{ fontWeight: 'bold' }} required>
          Exibir:
        </InputLabel>
        <InputSelectDefault
          height="35px"
          options={viewModes}
          value={model}
          onChange={(value) => {
            setValue('model', value);
          }}
          width="200px"
        />
      </InputContainer>

      <div className="h-35 flex align-center gap-025">
        <input
          {...register('showFinished')}
          type="checkbox"
          style={{ margin: '0' }}
          onChange={(e) => {
            setValue('showFinished', e.target.checked);
          }}
        />
        Exibir Finalizados
      </div>
    </div>
  );
}
