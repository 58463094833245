import React, { useEffect, useState } from 'react';

import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { FiltersYardManagement } from './components/FiltersYardManagement';

import { KanbanYardManagement } from './components/KanbanYardManagement';

export function YardManagement() {
  const methods = useForm({
    defaultValues: {
      showFinished: false,
      model: {
        value: 'both',
        label: 'Orçamento e OS Aberta',
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <DefaultContentContainer
        showRequiredFieldsLabel
        label="Apenas Orçamento e OS com veículo serão exibidas"
      >
        <BreadCrumb {...getBreadcrumbData()} />
        <section action="">
          <FiltersYardManagement />
          <KanbanYardManagement />
        </section>
      </DefaultContentContainer>
    </FormProvider>
  );
}
