import { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import plansRepository from 'v2/repositories/Plans';
import { BrandingRepository } from 'v2/repositories/BrandingRepository';

export const useBranding = () => {
  const branding = process.env.REACT_APP_BRANDING || 'OSD';

  const getBrandingAcronymById = (id) => {
    switch (id) {
      case 1:
        return 'OSD';
      case 2:
        return 'WM';
      case 3:
        return 'Tecnomotor';
      case 4:
        return 'Barros';
      case 5:
        return 'PO360';
      case 6:
        return 'Furacão';
      default:
        return '';
    }
  };

  const loadPlansByBranding = async (brandingId) => {
    try {
      const plans = await plansRepository.getAll(brandingId);
      return plans;
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao Carregar os Planos', 'Tente novamente');
    }
  };

  return {
    isBarros: branding === 'BARROS',
    isTecnomotor: branding === 'TECNOMOTOR',
    isWorkmotor: branding === 'WM',
    isPO360: branding === 'PO360',
    isFuracao: branding === 'Furacão',
    getBrandingAcronymById,
    loadPlansByBranding,
  };
};

export const useBrandingOptions = () => {
  const [brandingOptions, setBrandingOptions] = useState([]);

  const getBrandingOptions = async () => {
    try {
      const response = await BrandingRepository.getAll();
      setBrandingOptions(response);
    } catch (err) {
      toastr.error('Ocorreu algum erro ao buscar opções de branding');
    }
  };

  useEffect(() => {
    getBrandingOptions();
  }, []);

  return brandingOptions;
};
