import React, { useEffect } from 'react';
import { IdeaReviewBoxRight } from './IdeaReviewForm.styles';
import { InputSelectDefault, InputTextArea, InputText } from 'v2/components/Input';
import { useFormContext } from 'react-hook-form';
import { useIdeaWall } from '../../hooks/useIdeaWall';

export function IdeaReviewForm(props) {
  const { title, body, categoryId, subCategoryId } = props;
  const { watch, setValue, register } = useFormContext();
  const { getSubCategories } = useIdeaWall();

  const categoriesOptions = watch('categoriesOptions');
  const subCategoriesOptions = watch('subCategoriesOptions');

  const updateFormTitle = watch('ideaWallForm.updateForm.title');
  const updateFormBody = watch('ideaWallForm.updateForm.body');
  const updateFormFeedback = watch('ideaWallForm.updateForm.feedback');

  const updateFormCategory = watch('ideaWallForm.updateForm.category');
  const updateFormSubCategory = watch('ideaWallForm.updateForm.subCategory');

  const { form } = watch('ideaWallForm');
  const statusDescription = form?.statusDescription || 'Nova Ideia';
  
  useEffect(() => {
    const selectedCategory =
      categoriesOptions.find((category) => category.value === categoryId) || null;

    const selectedSubCategory =
      subCategoriesOptions.find(
        (subCategory) => subCategory.value === subCategoryId
      ) || null;

    setValue('ideaWallForm.updateForm.category', {
      label: selectedCategory?.label,
      value: selectedCategory?.value,
    });
    setValue('ideaWallForm.updateForm.subCategory', {
      label: selectedSubCategory?.label,
      value: selectedSubCategory?.value,
    });

    setValue('ideaWallForm.updateForm.title', title);
    setValue('ideaWallForm.updateForm.body', body);
    setValue('ideaWallForm.updateForm.feedback', updateFormFeedback || '');
  }, []);

  return (
    <IdeaReviewBoxRight>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'row',
          maxWidth: '80%',
        }}
      >
        <div style={{ flex: 1 }}>
          <InputSelectDefault
            height="35px"
            options={categoriesOptions || []}
            value={updateFormCategory}
            onChange={(value) => {
              setValue('ideaWallForm.updateForm.category', value);
              getSubCategories(value, setValue);
            }}
            width="100%"
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputSelectDefault
            height="35px"
            options={subCategoriesOptions || []}
            value={updateFormSubCategory}
            onChange={(value) => {
              setValue('ideaWallForm.updateForm.subCategory', value);
            }}
            width="100%"
          />
        </div>
      </div>
      <div>
        <InputText
          fontWeight="normal"
          value={updateFormTitle}
          style={{ font: '10px' }}
          {...register('ideaWallForm.updateForm.title')}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
        }}
      >
        <InputTextArea
          placeholder="Título"
          value={updateFormBody}
          height="100px"
          {...register('ideaWallForm.updateForm.body')}
        />
        <InputTextArea
          placeholder="Resposta da Ideia - Preencher apenas com motivo da Rejeição ou Ocultação."
          value={updateFormFeedback}
          height="100px"
          style={{ marginTop: '10px' }}
          disabled={statusDescription !== 'Nova Ideia'}
          {...register('ideaWallForm.updateForm.feedback')}
        />
        <span style={{ color: '#D9534F', fontSize: '12px' }}>
          Respostas de Rejeitado e Ocultação terão cabeçalho e rodapé automaticamente
        </span>
      </div>
    </IdeaReviewBoxRight>
  );
}
