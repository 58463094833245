import React from 'react';

export const KanbanRoot = ({
  children,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
}) => {
  return (
    <div
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      style={style.container}
    >
      {children}
    </div>
  );
};

const style = {
  container: {
    width: '200px',
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: 'calc(100vh - 250px)',
    borderRadius: '5px',
    border: '1px solid #CACACA',
    backgroundColor: '#F7F6F6',
    padding: '5px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #F7F6F6',
    cursor: 'move',
  },
  scroll: `
    div::-webkit-scrollbar {
      width: 5px;
    }
    div::-webkit-scrollbar-track {
      background: #F7F6F6; /* Fundo do scroll */
      border-radius: 5px;
    }
    div::-webkit-scrollbar-thumb {
      background: #888; /* Cor do scroll */
      border-radius: 5px;
    }
    div::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `,
};
