import api from 'services/api';

const getAllByCompanyId = async ({
  companyId,
  limit,
  page,
  showMaintenance,
}) => {
  if (!!companyId) {
    const res = await api.get(`/alert/${companyId}`, {
      params: {
        limit,
        page,
        showMaintenance,
      },
    });
    return res.data;
  }
  return null;
};

const AlertRepository = {
  getAllByCompanyId,
};

export default AlertRepository;
