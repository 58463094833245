import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.red400};
  padding: 7.5px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  flex-direction: row;
  gap: 5px;
  height: 35px;

  &span {
    font-weight: regular;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }
`;
