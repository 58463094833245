import React, { useEffect } from 'react';
import { format } from 'date-fns';

import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { SearchInput } from 'v2/components/form';
import { useIdeaWall } from '../hooks/useIdeaWall';
import { useFormContext } from 'react-hook-form';
import { IdeaWallFilterContainer } from '../IdeaWall.styles';
import { SelectMulti } from 'v2/components/Select/Multi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { SelectMultiBranding } from 'admin/v2/components/Input/Select/SelectMultiBranding';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { useConfigIdeaWallColumns } from '../hooks/useConfigIdeaWallColumns';

export function IdeaWallFilter() {
  const { setValue, watch, getValues, register } = useFormContext();
  const { columnsSheet } = useConfigIdeaWallColumns();
  const {
    dateTypes,
    searchByOptions,
    orderByOptions,
    getOptionsPlans,
    getCategoriesOptions,
    getSubCategories,
    getStatusOptions,
    getIdeasWallData,
    isLoading,
  } = useIdeaWall();

  const dateType = watch('dateType');
  const initialDate = watch('initialDate');
  const finalDate = watch('finalDate');
  const query = watch('query');
  const searchBy = watch('searchBy');
  const orderBy = watch('orderBy');
  const selectedBranding = watch('selectedBranding');
  const plans = watch('plans');
  const optionsPlans = watch('optionsPlans');
  const selectedPlans = watch('selectedPlans');
  const status = watch('status');
  const statusOptions = watch('statusOptions');
  const category = watch('category');
  const subCategory = watch('subCategory');
  const categoriesOptions = watch('categoriesOptions');
  const subCategoriesOptions = watch('subCategoriesOptions');

  const spreadsheet = watch('spreadsheet');

  useEffect(() => {
    if (selectedBranding?.length && plans?.length) {
      const options = getOptionsPlans(plans, selectedBranding);
      options.unshift({
        label: 'Vários',
        value: '*',
      });
      setValue('optionsPlans', options);
      setValue('selectedPlans', []);
    }
  }, [selectedBranding, plans]);

  useEffect(() => {
    getCategoriesOptions(setValue);
    getStatusOptions(setValue);
  }, []);

  const handleExportXml = async () => {
    return await getIdeasWallData(setValue, getValues, 'sheets', 1);
  };
  const placeholder =
    searchBy.value === 'Ideia'
      ? 'Pesquisar por título da ideia ou descrição'
      : 'Pesquisa por CPF/CNPJ, Nome/Razão Social, Nome Fantasia ou ID';

  return (
    <IdeaWallFilterContainer>
      <div className="filters">
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Tipo de Data:
          </InputLabel>
          <InputSelectDefault
            height="35px"
            options={dateTypes}
            value={dateType}
            onChange={(value) => {
              setValue('dateType', value);
            }}
            width="200px"
          />
        </div>
        <div>
          <InputText
            {...register('initialDate')}
            className="form-control foco-input fontSize-14"
            type="date"
            value={initialDate}
            onChange={(value) => {
              setValue('initialDate', value.target.value);
            }}
            height="35px"
            fontWeight="normal"
            max={finalDate}
          />
        </div>
        <div>
          <InputText
            {...register('finalDate')}
            className="form-control foco-input fontSize-14"
            type="date"
            height="35px"
            min={initialDate}
            onChange={(value) => {
              setValue('finalDate', value.target.value);
            }}
            value={finalDate}
            style={{ fontWeight: '' }}
            fontWeight="normal"
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Pesquisar por:
          </InputLabel>
          <InputSelectDefault
            height="35px"
            options={searchByOptions}
            value={searchBy}
            onChange={(value) => {
              setValue('searchBy', value);
            }}
            width="200px"
          />
        </div>
        <div>
          <SearchInput
            width="450"
            placeholder={placeholder}
            value={query}
            onChange={(e) => setValue('query', e.target.value)}
          />
        </div>
        <button
          className="button button-green button-h35"
          disabled={isLoading}
          onClick={() => {
            setValue('controlTable.page', 0);
            getIdeasWallData(setValue, getValues, 'table', 1);
          }}
        >
          Processar
        </button>
      </div>
      <div className="filters">
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Produtos:
          </InputLabel>
          <SelectMultiBranding
            value={selectedBranding}
            height={'35px'}
            onChange={(e) => setValue('selectedBranding', e)}
            showAllOption
            selectAllByDefault
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Planos:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={optionsPlans || []}
            value={selectedPlans}
            onChange={(value) => {
              setValue('selectedPlans', value);
            }}
            selectAllByDefault
            customAllLabel="Vários"
            hasAllOption
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Status Ideia:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={statusOptions || []}
            value={status}
            onChange={(value) => {
              setValue('status', value);
            }}
            selectAllByDefault
            customAllLabel="Todos"
            hasAllOption
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Ordenar por:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={orderByOptions}
            value={orderBy}
            onChange={(value) => {
              setValue('orderBy', value);
            }}
            selectAllByDefault
            customAllLabel="Todos"
            hasAllOption
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Categoria:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={categoriesOptions || []}
            value={category}
            onChange={(value) => {
              setValue('category', value);
              getSubCategories(value, setValue);
            }}
            selectAllByDefault
            customAllLabel="Todos"
            hasAllOption
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Sub-Categoria:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={subCategoriesOptions}
            value={subCategory}
            onChange={(value) => {
              setValue('subCategory', value);
            }}
            selectAllByDefault
            customAllLabel="Todos"
            hasAllOption
          />
        </div>
        <div className="flex center gap-025">
          {spreadsheet?.length ? (
            <DownloadXlsButton
              archiveName={`Ideias - ${format(new Date(), 'dd/MM/yyyy')}`}
              data={spreadsheet}
              className="btn btn-export"
              columns={columnsSheet}
              style={{
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                color="white"
                icon={faCloudDownloadAlt}
                style={{ marginRight: '5px' }}
              />
              Baixar Relatório
            </DownloadXlsButton>
          ) : (
            <button
              className="btn btn-export"
              onClick={() => handleExportXml()}
              disabled={isLoading}
              style={{
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp;Exportar .xls
            </button>
          )}
        </div>
      </div>
    </IdeaWallFilterContainer>
  );
}
