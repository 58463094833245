import { useAuth } from 'contexts/auth';
import { YardManagementRepository } from 'v2/repositories/YardManagementRepository';

export const useYardManagement = () => {
  const { companyId } = useAuth();
  const viewModes = [
    {
      value: 'both',
      label: 'Orçamento e OS Aberta',
    },
    {
      value: 'os',
      label: 'Apenas OS Aberta',
    },
    {
      value: 'budget',
      label: 'Apenas Orçamento Aberto ',
    },
  ];

  const handleColumns = async (showType, showFinished) => {
    let columns = await YardManagementRepository.getColumns(
      companyId,
      showType,
      showFinished
    );

    const columnToEnd = columns.find((col) => col.id === 2);
    columns = columns.filter((col) => col.id !== 2);

    if (columnToEnd) {
      columns.push(columnToEnd);
    }

    return columns;
  };

  async function moveCard(cardId, yardManagementId) {
    try {
      await YardManagementRepository.statusCard(cardId, yardManagementId);
      return;
    } catch (error) {
      console.error(error);
    }
  }
  async function moveColumn(column) {
    try {
      await YardManagementRepository.moveColumn(companyId, column);
      return;
    } catch (error) {
      console.error(error);
    }
  }
  async function deleteColumn(columnId) {
    try {
      await YardManagementRepository.deleteColumn(companyId, columnId);
      return;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    viewModes,
    handleColumns,
    moveCard,
    deleteColumn,
    moveColumn,
  };
};
