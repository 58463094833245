import api from '../services/api';

export const createAlert = async (body) => {
  const res = await api.post('/budget-alert/', body);
  return res.data;
};

export const update = async (id, body) => {
  const res = await api.put('/budget-alert/' + id, body);
  return res.data;
};

export const visualizeAll = async (companyId) => {
  const res = await api.put(`/budget-alert/visualize-all/${companyId}`);
  return res.data;
};

export const BudgetAlertRepository = {
  createAlert,
  update,
  visualizeAll,
};
