import React from 'react';
import {
  faCheck,
  faEyeSlash,
  faLightbulb,
  faThumbsUp,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export function IdeaWallStatusTable({ label }) {
  const statusPosts = {
    approval: {
      label: 'Ideia Entregue',
      color: '#5CB85C',
      icon: faCheck,
    },
    delivered: {
      label: 'Ideia Aprovada',
      color: '#428BCA',
      icon: faThumbsUp,
    },
    new: {
      label: 'Nova Ideia',
      color: '#5BC0DE',
      icon: faLightbulb,
    },
    rejected: {
      label: 'Ideia Rejeitada',
      color: '#D9534F',
      icon: faX,
    },
    hidden: {
      label: 'Ideia Oculta',
      color: '#2C3F50',
      icon: faEyeSlash,
    },
    default: {
      label: '-',
      color: '#808080',
    },
  };

  const statusInfo =
    Object.values(statusPosts).find((status) => status.label === label) ||
    statusPosts.default;

  const { label: statusLabel, color, icon } = statusInfo;
  return (
    <div style={{}}>
      <span
        style={{
          backgroundColor: color || 'gray',
          color: 'white',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '3px 12px',
          gap: '.8rem',
          minWidth: '135px',
          justifyContent: 'center',
        }}
      >
        {statusLabel}
        <FontAwesomeIcon icon={icon} />
      </span>
    </div>
  );
}
