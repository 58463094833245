import styled, { keyframes, css } from 'styled-components';

const blink = keyframes`
  0% { background: #f0ad4e; }
  50% { background: #fc6424; }
  100% { background: #f0ad4e; }
`;

export const Container = styled.div`
  background: ${(props) => (!props.count ? '#f0ad4e' : '#fc6424')};
  padding: 7.5px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  height: 35px;
  position: relative;
  animation: ${(props) =>
    props.isBlinking
      ? css`
          ${blink} 1s infinite
        `
      : 'none'};

  &:hover {
    filter: brightness(90%);
  }

  span {
    margin-left: 5px;
    white-space: nowrap;
    font-weight: regular;
  }
`;
