import React, { useEffect } from 'react';
import { DefaultContentContainer } from 'v2/components/layout';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { useForm, FormProvider } from 'react-hook-form';
import { IdeaWallContainer } from './IdeaWall.styles';
import { getBreadcrumbData } from './utils';
import { IdeaWallFilter } from './components/IdeaWallFilter';
import { useIdeaWall } from './hooks/useIdeaWall';
import { IdeaWallTable } from './components/IdeaWallTable';
import { InfoCard } from 'admin/components/InfoCard/InfoCard';

export function IdeaWall() {
  const { initialStartDate, initialEndDate, getPlans, populateInfoCardsData } =
    useIdeaWall();
  const methods = useForm({
    defaultValues: {
      dateType: { label: 'Criação', value: 'Criação' },
      initialDate: initialStartDate,
      finalDate: initialEndDate,
      searchBy: { label: 'Cliente', value: 'Cliente' },
      query: '',
      ideasWallDataTable: [],
      subCategoriesOptions: [{ label: 'Todos', value: '*' }],
      orderBy: { label: 'Mais Votadas', value: 'masVotadas' },
      controlTable: {
        page: 1,
        pageLimit: 10,
        totalPages: 1,
      },
      ideaWallForm: {
        isOpenModal: false,
        form: {},
        updateForm: {},
      },
    },
  });

  const ideasWallDataTable = methods.watch('ideasWallDataTable');

  const updatedInfoCardsData = populateInfoCardsData(ideasWallDataTable);

  useEffect(() => {
    getPlans(methods.setValue);
  }, []);

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />
      <FormProvider {...methods}>
        <IdeaWallContainer>
          <div>
            <IdeaWallFilter />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              marginTop: '2rem',
            }}
          >
            {updatedInfoCardsData?.map((card, index) => (
              <InfoCard
                key={index}
                icon={card.icon}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                containerStyle={card.containerStyle}
                iconStyle={card.iconStyle}
              />
            ))}
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            <IdeaWallTable />
          </div>
        </IdeaWallContainer>
      </FormProvider>
    </DefaultContentContainer>
  );
}
