import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Modal } from 'react-bootstrap';

import Card from '../../../components/Card/Card';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faMoneyBillAlt,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';

import useFilters from '../../../hooks/useFilters';
import purchasesRepository from '../../../repositories/Purchases';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';

import { currency } from '../../components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from '../../../utils/dateHelpers';
import PurchaseXmlImport from 'client/components/PurchaseXmlImport';
import BillsToPayModal from './BillsToPayModal/index';
import SelectPurchaseRegisterTypeModal from './components/SelectPurchaseRegisterTypeModal';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';

function HomePurchases() {
  const [isXMLModalOpen, setIsXMLModalOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [isBillsToPayModalOpen, setIsBillsToPayModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState([]);
  const [purchase, setPurchase] = useState(null);
  const [purchasesStatus, setPurchasesStatus] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [isGeneratedBills, setIsGeneratedBills] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [cancelPurchaseModal, setCancelPurchaseModal] = useState(false);
  const [purchaseId, setPurchaseId] = useState(null);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [
    isSelectPurchaseRegisterTypeModalOpen,
    setIsSelectPurchaseRegisterTypeModalOpen,
  ] = useState(false);

  const { companyId } = useAuth();
  const { statusFilter, typeFilter } = useFilters();
  const [query, setQuery] = useState('');

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);
  const { status, setStatus } = statusFilter;
  const { type, setType } = typeFilter;

  useEffect(() => {
    getPurchases();
    getPurchasesStatus();
  }, []);

  useEffect(() => {
    if (!isBillsToPayModalOpen) {
      setPurchase(null);
    }
  }, [isBillsToPayModalOpen]);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  async function getPurchases() {
    setLoading(true);

    try {
      const response = await purchasesRepository.getPurchases({
        companyId,
        page: page + 1,
        query: queryDebounced,
        type,
        status,
        initialDate,
        finalDate,
        limit,
        generatedBills: isGeneratedBills,
      });
      setPages(Math.ceil(response.count / limit));
      setPurchases(response.rows);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  async function getPurchasesStatus() {
    try {
      const response = await purchasesRepository.getPurchasesStatus();
      setPurchasesStatus(response);
    } catch (err) {
      console.log(err);
    }
  }

  function handleColorStatus(value) {
    const status = value?.toLowerCase();
    if (status?.includes('abert')) return '#ffcc00';
    if (status?.includes('finaliz')) return '#5cb85c';
    if (status?.includes('cancelad')) return '#d9534f';
    return 'black';
  }

  function getBackgroundColorByPurchaseType(purchaseType) {
    const backgroundColors = {
      XML: '#428bca',
      Manual: '#5cb85c',
    };

    return backgroundColors[purchaseType];
  }

  function handleOpenBillsToPayModal(purchase) {
    const { hasBills, purchaseStatusId } = purchase;
    if (purchaseStatusId !== constants.PURCHASES_STATUS.CLOSED && !hasBills) {
      toastr.error(
        'Os títulos só podem ser gerados quando a compra estiver finalizada'
      );
      return;
    }

    setPurchase(purchase);
    setIsBillsToPayModalOpen(true);
  }

  const handleSubmit = () => {
    setIsXMLModalOpen(false);
    getPurchases();
  };

  async function cancel() {
    try {
      await purchasesRepository.cancel(purchaseId);
      toastr.success('Compra cancelada com sucesso!');
    } catch (err) {
      console.log(err);
      toastr.error('Houve um erro ao tentar cancelar a compra.');
    }
    setCancelPurchaseModal(false);
    await getPurchases();
    await getPurchasesStatus();
  }

  const validations = () => {
    if ((initialDate && !finalDate) || (!initialDate && finalDate)) {
      toastr.warning('Informe a data inicial e final.');
      return false;
    }
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!!validations() && !!companyId) {
      getPurchases();
    }
  }, [
    page,
    limit,
    queryDebounced,
    type,
    status,
    initialDate,
    finalDate,
    limit,
    isGeneratedBills,
  ]);

  return (
    <>
      <Card
        content={
          <>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso button-h35"
                  onClick={() =>
                    !!companyId
                      ? setIsSelectPurchaseRegisterTypeModalOpen(true)
                      : setNoCompanyModal(true)
                  }
                  style={{ fontSize: '12px' }}
                >
                  + Nova Compra
                </button>
              </div>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Compra, Nota, Pedido ou Fornecedor"
                  value={query}
                  onChange={(e) => handleChangeQuery(e.target.value)}
                  background={'#FFFFFF'}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '320px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Tipo:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="purchaseType"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="XML">XML</option>
                  <option value="Manual">Manual</option>
                </select>
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Status:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Todos</option>
                  {purchasesStatus.map((s) => (
                    <option key={s.id} value={s.description}>
                      {s.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Inicial:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Final:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Títulos Gerados:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={isGeneratedBills}
                  onChange={(e) => setIsGeneratedBills(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                }}
                data={purchases}
                columns={[
                  {
                    Header: 'Dt. Entrada',
                    accessor: 'entryAt',
                    width: 100,
                    Cell: (props) =>
                      format(
                        new Date(
                          getDateOnlyFromDate(
                            props.value !== '0000-00-00' && purchase?.entryAt
                              ? props.value
                              : props.original.createdAt
                          )
                        ),
                        'dd/MM/yyyy'
                      ),
                  },
                  {
                    Header: 'Compra',
                    accessor: 'code',
                    headerClassName: 'text-center',
                    width: 70,
                    className: 'texto',
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'type',
                    headerClassName: 'text-center',
                    width: 120,
                    className: 'texto',
                    Cell: (props) => {
                      return (
                        <button
                          className="admin-custom-label"
                          style={{
                            backgroundColor: getBackgroundColorByPurchaseType(
                              props.value
                            ),
                          }}
                        >
                          {props.value}
                        </button>
                      );
                    },
                  },
                  {
                    Header: 'Nota',
                    accessor: 'invoice',
                    headerClassName: 'text-center',
                    width: 60,
                    className: 'texto',
                  },
                  {
                    Header: 'Pedido',
                    accessor: 'request',
                    headerClassName: 'text-center',
                    width: 80,
                    className: 'texto',
                  },
                  {
                    Header: 'Fornecedor',
                    accessor: 'Provider.companyName',
                    headerClassName: 'text-center',
                    className: 'texto',
                  },
                  {
                    Header: 'Valor',
                    accessor: 'total',
                    width: 110,
                    Cell: (props) => currency(props.value),
                  },
                  {
                    Header: 'Status',
                    accessor: 'PurchaseStatus.description',
                    width: 150,
                    Cell: (props) => (
                      <button
                        className="admin-custom-label"
                        style={{
                          backgroundColor: handleColorStatus(props.value),
                        }}
                      >
                        {props.value}
                      </button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    filterable: false,
                    width: 100,
                    Cell: ({ original, value }) => (
                      <>
                        <Link to={{ pathname: `purchase#${value}` }}>
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: 'black',
                              marginRight: '4px',
                            }}
                            icon={faEdit}
                          />
                        </Link>
                        {original.purchaseStatusId ===
                          constants.PURCHASES_STATUS.OPEN ||
                        original.purchaseStatusId ===
                          constants.PURCHASES_STATUS.CLOSED ? (
                          <FontAwesomeIcon
                            title="Cancelar"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: 'red',
                            }}
                            icon={faWindowClose}
                            onClick={() => {
                              setPurchaseId(value);
                              setCancelPurchaseModal(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ),
                  },
                  {
                    Header: 'Financeiro',
                    accessor: 'hasBills',
                    filterable: false,
                    width: 100,
                    Cell: ({ original, value }) => (
                      <FontAwesomeIcon
                        cursor={
                          original.purchaseStatusId !==
                            constants.PURCHASES_STATUS.CLOSED && !value
                            ? 'not-allowed'
                            : 'pointer'
                        }
                        style={{
                          height: '1.5em',
                          width: '2em',
                          color: !!value ? '#629c44' : '#aaaaaa',
                        }}
                        icon={faMoneyBillAlt}
                        onClick={() => handleOpenBillsToPayModal(original)}
                      />
                    ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination
                sortable
                page={page}
                pages={pages}
                onPageChange={(page) => setPage(page)}
                pageSize={limit}
                onPageSizeChange={(size) => setLimit(size)}
                manual
                showPaginationTop={false}
                showPaginationBottom
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'code',
                    desc: true,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum Produto encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </>
        }
      />
      <Modal
        show={isXMLModalOpen}
        onHide={() => setCancelModal(true)}
        dialogClassName="modal-95w modal-marginTop10"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Importação de XML de Compra</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body">
          <PurchaseXmlImport onSubmit={handleSubmit} />
        </Modal.Body>
      </Modal>

      {cancelModal && (
        <AlertModal
          show={cancelModal}
          onHide={() => setCancelModal(false)}
          onCancel={() => setCancelModal(false)}
          message="Deseja cancelar a importação de XML?"
          onSubmit={() => {
            setCancelModal(false);
            setIsXMLModalOpen(false);
          }}
        />
      )}

      {cancelPurchaseModal && (
        <AlertModal
          title="Cancelamento de Compra"
          show={cancelPurchaseModal}
          onHide={() => setCancelPurchaseModal(false)}
          onCancel={() => setCancelPurchaseModal(false)}
          message="Deseja cancelar compra? Todos os produtos que tiveram seus estoques creditados, serão devolvidos."
          onSubmit={cancel}
        />
      )}
      {isBillsToPayModalOpen && (
        <BillsToPayModal
          purchase={purchase}
          handleReloadPurchases={getPurchases}
          handleCancel={() => setIsBillsToPayModalOpen(false)}
        />
      )}

      {isSelectPurchaseRegisterTypeModalOpen && (
        <SelectPurchaseRegisterTypeModal
          handleClose={() => setIsSelectPurchaseRegisterTypeModalOpen(false)}
          handleOpenImportXMLModal={() => setIsXMLModalOpen(true)}
        />
      )}
    </>
  );
}

export default HomePurchases;
