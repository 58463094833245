import React, { useEffect } from 'react';
import { Table } from 'v2/components/Table';
import { useFormContext } from 'react-hook-form';
import { useIdeaWall } from '../hooks/useIdeaWall';
import { useConfigIdeaWallColumns } from '../hooks/useConfigIdeaWallColumns.jsx';
import { IdeaReviewModal } from './IdeaReviewModal/IdeaReviewModal';

export function IdeaWallTable() {
  const { watch, setValue, getValues } = useFormContext();
  const { page, pageLimit, totalPages } = watch('controlTable');

  const { columns } = useConfigIdeaWallColumns(setValue);
  const { getIdeasWallData, isLoading } = useIdeaWall();

  const ideasWallDataTable = watch('ideasWallDataTable');

  useEffect(() => {
    getIdeasWallData(setValue, getValues, 'table');
  }, [page, pageLimit]);

  return (
    <div className="mt-2">
      <Table
        width="100%"
        columns={columns}
        data={ideasWallDataTable?.ideas || []}
        pageSize={pageLimit}
        page={page - 1}
        onPageChange={(value) => setValue('controlTable.page', value + 1)}
        manual
        onPageSizeChange={(value) => setValue('controlTable.pageLimit', value)}
        pages={totalPages}
        loading={isLoading}
      />
      <IdeaReviewModal
        handleClose={() => setValue('ideaWallForm.isOpenModal', false)}
      />
    </div>
  );
}
