import CustomButton from 'client/components/CustomButton/CustomButton';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { GroupedInvoiceEmissionFilter } from './GroupedInvoiceEmissionFilter';
import { useForm, FormProvider } from 'react-hook-form';
import { GroupedInvoiceEmissionTable } from './GroupedInvoiceEmissionTable';
import AlertModal from 'components/AlertModal/AlertModal';
import {
  useGroupedInvoiceEmission,
  useTotalParcels,
} from '../hooks/useGroupedInvoiceEmission';
import { LimitExceedModal } from '../LimitExceedModal';
export const GroupedInvoiceEmission = ({ isOpen, handleCloseModal }) => {
  const methods = useForm({
    defaultValues: {
      searchInput: null,
      typeDate: {
        value: 'closingDate',
        label: 'Fechamento',
      },
      initialDate: '',
      finalDate: '',
      listSaleInvoices: [],
      selectedSaleInvoices: [],
      page: 1,
      pageLimit: 10,
    },
  });
  const { isExceedingLimit } = useTotalParcels({
    getValues: methods.getValues,
  });
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] =
    React.useState(false);
  const [isLimitExceedModalOpen, setIsLimitExceedModalOpen] =
    React.useState(false);

  const selectedSaleInvoices = methods.watch('selectedSaleInvoices');
  const { handleSendGroupedInvoices, isLoading } = useGroupedInvoiceEmission(
    methods.getValues,
    methods.setValue
  );

  const resetForm = () => {
    methods.reset();
    handleCloseModal(false);
  };
  useEffect(() => {
    if (isExceedingLimit) {
      setIsLimitExceedModalOpen(true);
    }
  }, [isExceedingLimit]);

  const sendGroupedInvoices = async () => {
    await handleSendGroupedInvoices();
    setIsOpenModalConfirmation(false);
    resetForm();
  };
  return (
    <FormProvider {...methods}>
      <Modal
        show={isOpen}
        onHide={() => resetForm()}
        animation
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <strong>Emissão Agrupada</strong>
              <span
                style={{
                  color: '#d43f3a',
                  fontSize: '12px',
                  marginRight: '1.5rem',
                }}
              >
                Apenas Vendas/OS (com produtos) finalizadas e que não possuem
                NF-e criadas serão exibidas
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GroupedInvoiceEmissionFilter />
          <GroupedInvoiceEmissionTable />
        </Modal.Body>
        <Modal.Footer>
          <div className=" ">
            <CustomButton
              bsStyle="danger"
              name="Cancelar"
              style={{
                fontSize: '12px',
              }}
              onClick={() => {
                resetForm();
              }}
              fill
            >
              Cancelar
            </CustomButton>
            &nbsp;
            <CustomButton
              bsStyle="info"
              name="Editar"
              style={{
                backgroundColor: '#5cb85c',
                borderColor: '#5cb85c',
                fontSize: '12px',
              }}
              onClick={() => setIsOpenModalConfirmation(true)}
              fill
              disabled={selectedSaleInvoices.length <= 1 || isExceedingLimit}
            >
              Gerar NF-e
            </CustomButton>
          </div>
        </Modal.Footer>
        <AlertModal
          title="Criação de nota"
          subtitle="Você tem certeza que deseja criar a nota com as vendas selecionadas?"
          message="Será criado uma nota para todas as vendas selecionadas e não poderá
          adicionar ou remover nenhuma venda da listagem, será necessário
          excluir a nota e criar novamente."
          show={isOpenModalConfirmation}
          onCancel={() => setIsOpenModalConfirmation(false)}
          onHide={() => setIsOpenModalConfirmation(false)}
          onSubmit={sendGroupedInvoices}
          loading={isLoading}
        />
      </Modal>
      <LimitExceedModal
        isOpen={isLimitExceedModalOpen}
        handleClose={() => setIsLimitExceedModalOpen(false)}
      />
    </FormProvider>
  );
};
