import React from 'react';
import { IdeaReviewBoxLeft } from './IdeaReviewForm.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { format } from 'date-fns';
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values';
export function IdeaReviewCustomerData(props) {
  const {
    brandingName,
    planName,
    companyName,
    razaoSocial,
    cpfCnpj,
    phoneNumber,
    Email,
    createdAt,
    deliveredAt,
    approvedAt,
  } = props;
  return (
    <IdeaReviewBoxLeft>
      <div className="infoCustomer">
        <div className="boxInfo">
          <strong>{companyName}</strong>
          <span>{razaoSocial}</span>
          <span>{cpfCnpj ? formatToCPFOrCNPJ(cpfCnpj) : '-'}</span>
        </div>
        <div className="boxInfo">
          <span>
            <strong>Produto: </strong>
            <span>{brandingName}</span>
          </span>
          <span>
            <strong>Plano: </strong>
            <span>{planName}</span>
          </span>
        </div>
      </div>
      <div className="infoCustomer">
        <div className="boxInfo">
          <span>
            <strong>Telefone: </strong>
            <span>{phoneNumber ? formatToPhone(phoneNumber) : '-'}</span>
          </span>
          <span>
            <strong>Email: </strong>
            <span>{Email}</span>
          </span>
        </div>
        <div className="boxInfo">
          <span className="postActionHistoryTime">
            <FontAwesomeIcon icon={faClock} />
            <strong> Criada em:</strong>
            {createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : '-'}
          </span>

          <span className="postActionHistoryTime">
            <FontAwesomeIcon icon={faClock} />
            <strong>Aprovada em:</strong>
            {approvedAt ? format(new Date(approvedAt), 'dd/MM/yyyy') : '-'}
          </span>

          <span className="postActionHistoryTime">
            <FontAwesomeIcon icon={faClock} />
            <strong>Entregue em:</strong>
            {deliveredAt ? format(new Date(deliveredAt), 'dd/MM/yyyy') : '-'}
          </span>
        </div>
      </div>
    </IdeaReviewBoxLeft>
  );
}
