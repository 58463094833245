import { text } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { Link } from 'react-router-dom';
import { kanbanUtils } from '../utils';

export function KanbanCardHeader({ card, urlDirectory }) {
  const { License_Plate, Code, vehicleModel, id, SalesType_id } = card;
  const { descriptionSaleType } = kanbanUtils;
  return (
    <header
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '5px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <strong
          style={{
            fontSize: '12px',
            width: '60px',
            textTransform: 'uppercase',
          }}
        >
          {License_Plate}
        </strong>
        {Code && (
          <div
            style={{
              fontSize: '11px',
              color: '#00A4D3',
              flex: 1,
              justifyContent: 'end',
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <span>{descriptionSaleType(SalesType_id)}:</span>
            <Link
              style={{
                textDecoration: 'underline',
                color: '#00A4D3',
                fontWeight: 'bold',
              }}
              to={{ pathname: `${urlDirectory}` }}
            >
              {Code}
            </Link>
          </div>
        )}
      </div>
      <span style={{ fontSize: '11px', color: '#444444' }}>{vehicleModel}</span>
    </header>
  );
}
