import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { useDispatch, connect, useSelector } from 'react-redux';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { change, reduxForm, initialize } from 'redux-form';
import { addDays, addHours, format, isAfter, parseISO } from 'date-fns';
import { Modal } from 'react-bootstrap';

import PureLoader from 'components/PureLoader';
import FormBillToReceive from './FormBillToReceive';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import BankSlipErrorsModal from 'client/components/BankSlip/BankSlipErrorsModal';
import BankSlipUpdateConfirmModal from 'client/components/BankSlip/BankSlipUpdateConfirmModal';

import { useAuth } from 'contexts/auth';
import constants from '../../../../utils/constants';
import billsToReceiveRepository from '../../../../repositories/BillsToReceive';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import customersRepository from 'repositories/Customers';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import customerCreditRepository from 'repositories/CustomerCredit';
import { SaveBillModal } from './SaveBillModal';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { BillsToReceiveRepositoryV2 } from 'v2/repositories/BillsToReceiveRepository';
import { LowBillReceiveShowModal } from './LowBillReceiveShowModal';
import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';
import { getDDMMYYYYDateFromString } from 'utils/dateHelpers';

const initialValues = {
  issueDate: format(new Date(), 'yyyy-MM-dd'),
  clientId: null,
  searchClient: '',
  billStatusId: constants.BILLS_STATUS.OPEN,
  amount: 0,
  generateDiffTitle: false,
  discount: 0,
  bankSlipId: null,
  addition: 0,
  liquidValue: 0,
  openValue: 0,
  fee: 0,
  feeValue: 0,
  payments: [
    {
      formOfPaymentId: '',
      value: 0,
      payDate: '',
      observations: '',
    },
  ],
  paidValue: 0,
  monthlyInterestValue: 0,
  conciliationIdentifier: '',
  conciliationDate: null,
  billToReceiveId: null,
  haveBankConciliation: false,
};

function BundleFormBillToReceive({
  history,
  titleId,
  billToReceive,
  defaultValues,
}) {
  const bill = useSelector((state) => state.form.billToReceive?.values);

  const [loading, setLoading] = useState(false);

  // Modals
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [bankSlipErrorsModal, setBankSlipErrorsModal] = useState(false);
  const [isCancelBillModalOpen, setIsCancelBillModalOpen] = useState(false);
  const [showUpdateBankSlipConfirmModal, setShowUpdateBankSlipConfirmModal] =
    useState(false);
  const [isRemoveConciliationModalOpen, setIsRemoveConciliationModalOpen] =
    useState(false);
  const [isLowBillModalOpen, setIsLowBillModalOpen] = useState(false);
  const [pendingBillValues, setPendingBillValues] = useState(null);

  // Bank slip errors
  const [bankSlipErrors, setBankSlipErrors] = useState([]);
  const [bankSlipErrorStatus, setBankSlipErrorStatus] = useState(0);

  // Data
  const [originalBillToReceive, setOriginalBillToReceive] = useState({});

  const { company, companyId, userId } = useAuth();
  const dispatch = useDispatch();

  const generateTitleDiffConfig = company.companyConfig.generateTitleDiff;

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;
  const hasCashierControl = company?.hasCashierControl;

  useEffect(() => {
    if (!!titleId) {
      getTitle();
    } else {
      initialValuesWithDefaultCashierBank();
    }
  }, []);

  async function initialValuesWithDefaultCashierBank() {
    const data = {
      ...initialValues,
      cashierBankId: company.defaultCashierBankId,
      accountPlanId: company.defaultSaleAccountPlanId,
    };

    if (defaultValues) {
      data.amount = defaultValues.total;
      data.isGrouped = defaultValues.grouped;
      data.clientId = defaultValues.customerId;
      data.searchClientName = defaultValues.customerName;

      data.payments = [
        {
          formOfPaymentId: '',
          value: defaultValues.total,
          payDate: '',
          observations: '',
        },
      ];

      defaultValues.salesCodes.sort((a, b) => {
        if (parseInt(a) < parseInt(b)) {
          return -1;
        }

        if (parseInt(a) > parseInt(b)) {
          return 1;
        }

        return 0;
      });

      data.salesIds = defaultValues.salesIds;
      data.linkedSales = defaultValues.salesCodes.join(', ');

      if (defaultValues.grouped) {
        data.observations = 'Título Agrupado';
      } else {
        data.code = defaultValues.code;
      }
    }
    dispatch(initialize('billToReceive', data));
  }

  async function getTitle() {
    setLoading(true);
    try {
      const billToReceive = await billsToReceiveRepository.getById(titleId);
      setOriginalBillToReceive(billToReceive);

      const customersList = await indexCustomersWithVehicles({
        company_id: companyId,
        page: 1,
        limit: 10,
        query:
          billToReceive.Customer.Company_Name ||
          billToReceive.Customer.Trading_Name,
        show_label: false,
        show_vehicles: false,
      });

      const currentCustomer = customersList.rows.find(
        (item) => item.customer_id === billToReceive.customerId
      );

      const currentCustomerLabel = currentCustomer
        ? `${currentCustomer.customer_name} ${
            currentCustomer.customer_cpfcnpj &&
            `- ${cpfOrCnpjMask(currentCustomer.customer_cpfcnpj)}`
          }`
        : '';

      let salesCodes = '';

      if (billToReceive.isGrouped) {
        salesCodes = billToReceive.BillsToReceiveSales.map(
          (billToReceiveSales) => billToReceiveSales.Sales.Code
        )?.join(', ');
      } else {
        salesCodes =
          billToReceive.BillsToReceiveSales[0]?.Sales.Code ||
          billToReceive.Sales?.Code;
      }

      dispatch([
        change(
          'billToReceive',
          'dueDate',
          format(
            new Date(getDateOnlyFromDate(billToReceive.dueDate)),
            'yyyy-MM-dd'
          )
        ),
        change(
          'billToReceive',
          'selectedCustomer',
          currentCustomer
            ? {
                ...currentCustomer,
                label: currentCustomerLabel,
              }
            : {}
        ),
        change('billToReceive', 'linkedSales', salesCodes),
        change('billToReceive', 'amount', billToReceive.subTotal),
        change('billToReceive', 'openValue', billToReceive.openValue),
        change('billToReceive', 'liquidValue', billToReceive.liquidValue),
        change('billToReceive', 'isGrouped', billToReceive.isGrouped),
        change(
          'billToReceive',
          'searchClientName',
          billToReceive.Customer.Company_Name
        ),
        change('billToReceive', 'clientId', billToReceive.customerId),
        change(
          'billToReceive',
          'issueDate',
          format(
            new Date(getDateOnlyFromDate(billToReceive.issuedAt)),
            'yyyy-MM-dd'
          )
        ),
        change('billToReceive', 'saleCode', billToReceive.saleId),
        change('billToReceive', 'parcelNumber', billToReceive.installment),
        change(
          'billToReceive',
          'saleDate',
          billToReceive.saleDate
            ? format(
                new Date(getDateOnlyFromDate(billToReceive.saleDate)),
                'yyyy-MM-dd'
              )
            : null
        ),
        change('billToReceive', 'addition', billToReceive.addedValue),
        change('billToReceive', 'discount', billToReceive.discountValue),
        change(
          'billToReceive',
          'dischargeDate',
          billToReceive.lowDate
            ? format(new Date(billToReceive.lowDate), 'dd/MM/yyyy HH:mm')
            : null
        ),
        change('billToReceive', 'fee', billToReceive.fee),
        change('billToReceive', 'feeValue', billToReceive.feeValue),
        change('billToReceive', 'observations', billToReceive.observations),
        change('billToReceive', 'billStatusId', billToReceive.billStatusId),
        change('billToReceive', 'code', billToReceive.code),
        change('billToReceive', 'cashierBankId', billToReceive.cashierBankId),
        change('billToReceive', 'accountPlanId', billToReceive.accountPlanId),
        change(
          'billToReceive',
          'bank_slip_barcode',
          billToReceive.bank_slip_barcode
        ),
        change('billToReceive', 'bankSlipId', billToReceive.bankSlipId),
        change(
          'billToReceive',
          'bankBilletAccount',
          billToReceive.CompanyBankBilletAccount?.TicketIssuanceBank?.name
        ),
        change('billToReceive', 'bank_slip_fee', billToReceive.bank_slip_fee),
        change('billToReceive', 'bank_slip_fine', billToReceive.bank_slip_fine),
        change(
          'billToReceive',
          'bank_slip_status_description',
          billToReceive.bank_slip_status_description
        ),
        change('billToReceive', 'billToReceiveId', billToReceive.id),
        change(
          'billToReceive',
          'haveBankConciliation',
          billToReceive.ofxHeaderId
        ),
        change(
          'billToReceive',
          'conciliationIdentifier',
          billToReceive.Conciliation
            ? billToReceive.Conciliation.identifier
            : null
        ),
        change(
          'billToReceive',
          'conciliationDate',
          billToReceive.Conciliation
            ? format(
                new Date(
                  getDateOnlyFromDate(billToReceive.Conciliation.updatedAt)
                ),
                'yyyy-MM-dd'
              )
            : null
        ),
      ]);

      const payments = billToReceive.BillsToReceiveParcels.map(
        (billToReceiveParcel) => ({
          id: billToReceiveParcel.id,
          value: billToReceiveParcel.amount,
          paymentDate: !billToReceiveParcel.payDate
            ? null
            : format(
                new Date(getDateOnlyFromDate(billToReceiveParcel.payDate)),
                'yyyy-MM-dd'
              ),
          observations: billToReceiveParcel.observations,
          formOfPaymentId: billToReceiveParcel.formOfPaymentId,
          isCustomerCreditPayment:
            billToReceiveParcel.FormOfPayment.TypeOfPayment === 'Crédito Loja',
        })
      );

      dispatch(change('billToReceive', 'payments', payments));
      dispatch(
        change(
          'billToReceive',
          'monthlyInterestValue',
          billToReceive.monthlyInterestValue
        )
      );

      if (billToReceive.billStatusId === constants.BILLS_STATUS.CANCELED) {
        toastr.warning('Título Cancelado. Nenhuma ação poderá ser realizada.');
      }
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(values, generateDiffTitle) {
    try {
      setShowUpdateBankSlipConfirmModal(false);

      const {
        amount,
        clientId,
        dueDate,
        openValue,
        payments,
        cashierBankId,
        accountPlanId,
        discount,
        paidValue,
      } = values;

      if (
        !clientId ||
        !dueDate ||
        !amount ||
        !cashierBankId ||
        !accountPlanId
      ) {
        return toastr.warning('Por favor, insira os campos obrigatórios');
      }

      let isFullDiscount = discount === amount && openValue === 0;

      const anyPaymentFieldEmpty = payments.some(
        (payment) => !payment.formOfPaymentId || !payment.value
      );
      if (anyPaymentFieldEmpty && !isFullDiscount) {
        return toastr.warning(
          'Existem pagamentos sem Forma de pagamento ou Valor'
        );
      }

      if (openValue < 0) {
        return toastr.warning('Somatório dos pagamentos maior que o total');
      }

      if (openValue > 0 && (generateTitleDiffConfig ? paidValue === 0 : true)) {
        if (titleId) {
          update(
            constants.BILLS_STATUS.OPEN,
            values,
            generateTitleDiffConfig ? generateDiffTitle : false
          );
        } else {
          create(
            constants.BILLS_STATUS.OPEN,
            values,
            generateTitleDiffConfig ? generateDiffTitle : false
          );
        }
      } else {
        const cashierValidation = await cashierBankHistoricValidations(
          companyId
        );

        const isCashierClosed = !cashierValidation.payload;

        if (!hasCashierControl) {
          setIsSaveModalOpen(true);
          return;
        }
        if (isCashierClosed) {
          setPendingBillValues(values);
          setIsLowBillModalOpen(true);
        } else {
          setIsSaveModalOpen(true);
        }
      }
    } catch (error) {
      toastr.error(
        'Erro ao processar a requisição',
        'Por favor, tente novamente'
      );
    }
  }

  const handleConfirmLowBill = () => {
    setIsLowBillModalOpen(false);
    if (pendingBillValues) {
      setIsSaveModalOpen(true);
    }
  };

  async function handleConfirmModal(billStatus, generateDiffTitle) {
    const { payments } = billToReceive;
    setIsSaveModalOpen(false);
    setIsCancelBillModalOpen(false);

    dispatch(change('billToReceive', 'generateDiffTitle', generateDiffTitle));

    if (billStatus === constants.BILLS_STATUS.CLOSED) {
      const anyPaymentDateIsFuture = payments.some((payment) =>
        isAfter(new Date(getDateOnlyFromDate(payment.paymentDate)), new Date())
      );
      if (anyPaymentDateIsFuture) {
        return toastr.error(
          currentBrandingName,
          'Não é possível efetuar a baixa do título com data de pagamento futura. Valide a data e tente novamente'
        );
      }

      const anyPaymentDateIsPastTenYears = payments.some(
        (payment) =>
          new Date(getDateOnlyFromDate(payment.paymentDate)).getFullYear() <
          2010
      );
      if (anyPaymentDateIsPastTenYears) {
        return toastr.error(
          currentBrandingName,
          `Data inferior à 2010. Valide a data e tente novamente`
        );
      }
    }

    if (billStatus === constants.BILLS_STATUS.CANCELED)
      update(billStatus, billToReceive, generateDiffTitle);

    if (!titleId) {
      create(billStatus, billToReceive, generateDiffTitle);
    } else {
      update(billStatus, billToReceive, generateDiffTitle);
    }
  }

  async function handleCustomerCredit(status, clientId, payments) {
    if (status !== constants.BILLS_STATUS.CLOSED) return;

    const paymentWithCustomerCredit = payments.filter(
      (installment) => installment.isCustomerCreditPayment
    );

    const hasCustomerCreditPayment = paymentWithCustomerCredit.length > 0;

    if (hasCustomerCreditPayment) {
      const customerCreditPaymentValue = paymentWithCustomerCredit.reduce(
        (prev, curr) => prev + Number(curr.value),
        0
      );

      const { availableCredit } = await customersRepository.show(clientId);

      if (
        availableCredit > customerCreditPaymentValue &&
        clientId &&
        isPlanPrime &&
        company?.companyConfig?.manageInternalCredit
      ) {
        await customerCreditRepository.create({
          customerId: clientId,
          value: customerCreditPaymentValue,
          type: 'Saída',
          reason: 'Venda',
        });
      }
    }
  }

  async function update(billStatusId, bill, generateDiffTitle) {
    const {
      addition,
      amount,
      clientId,
      discount,
      dueDate,
      issueDate,
      cashierBankId,
      liquidValue,
      observations,
      openValue,
      paidValue,
      payments,
      code,
      fee,
      feeValue,
      accountPlanId,
      isGrouped,
    } = bill;

    await handleCustomerCredit(billStatusId, clientId, payments);
    const billToReceive = {
      dueDate,
      code,
      cashierBankId,
      issuedAt: issueDate,
      subTotal: amount,
      addedValue: addition,
      discountValue: discount,
      liquidValue,
      paidValue,
      openValue,
      generateDiffTitle,
      fee,
      feeValue,
      observations,
      customerId: clientId,
      billStatusId: billStatusId,
      companyId,
      lowDate:
        billStatusId === constants.BILLS_STATUS.CLOSED
          ? format(addHours(new Date(), 3), 'yyyy-MM-dd HH:mm')
          : null,
      accountPlanId,
      isGrouped,
    };

    setLoading(true);
    try {
      await BillsToReceiveRepositoryV2.update({
        billToReceive,
        payments,
        titleId,
        companyId,
      });

      toastr.success(
        `Título ${
          billStatusId === constants.BILLS_STATUS.OPEN
            ? 'Salvo'
            : billStatusId === constants.BILLS_STATUS.CANCELED
            ? 'Cancelado'
            : 'Finalizado'
        } com sucesso`
      );
      history.push(constants.ROUTES.BILLS_TO_RECEIVE);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao atualizar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function create(billStatusId, bill, generateDiffTitle) {
    const {
      addition,
      amount,
      clientId,
      discount,
      dueDate,
      issueDate,
      cashierBankId,
      liquidValue,
      observations,
      openValue,
      paidValue,
      payments,
      fee,
      feeValue,
      accountPlanId,
      salesIds,
      isGrouped,
    } = bill;

    if (billStatusId === constants.BILLS_STATUS.CLOSED) {
      const firstPaymentHasDate = !!payments[0].paymentDate;

      if (!firstPaymentHasDate) {
        toastr.warning(
          '1ª parcela sem data de pagameto',
          'Informe uma data de pagamento para a primeira parcela'
        );

        setIsSaveModalOpen(false);
        return;
      }
    }

    await handleCustomerCredit(billStatusId, clientId, payments);

    const billToReceive = {
      dueDate,
      cashierBankId,
      issuedAt: issueDate,
      subTotal: amount,
      addedValue: addition,
      discountValue: discount,
      liquidValue,
      generateDiffTitle,
      paidValue,
      openValue,
      fee,
      feeValue,
      observations,
      lowDate:
        billStatusId === constants.BILLS_STATUS.CLOSED ? new Date() : null,
      customerId: clientId,
      billStatusId,
      accountPlanId,
      isGrouped,
    };
    setLoading(true);

    try {
      await BillsToReceiveRepositoryV2.create({
        billToReceive,
        salesIds,
        payments,
        companyId,
      });

      toastr.success(
        `Título ${
          billStatusId === constants.BILLS_STATUS.OPEN ? 'Salvo' : 'Finalizado'
        } com sucesso`
      );
      history.push(constants.ROUTES.BILLS_TO_RECEIVE);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao salvar o título. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    if (billToReceive.billStatusId === constants.BILLS_STATUS.OPEN) {
      setIsCancelModalOpen(true);
    } else {
      setIsCancelBillModalOpen(true);
    }
  }

  const handleRemoveBankConciliation = async () => {
    try {
      await billsToReceiveRepository.removeBankConciliation(
        billToReceive.billToReceiveId,
        userId
      );
      dispatch([
        change('billToReceive', 'conciliationIdentifier', null),
        change('billToReceive', 'conciliationDate', null),
        change('billToReceive', 'haveBankConciliation', false),
      ]);
      toastr.success(
        'Conciliação Removida',
        'A conciliação foi removida com sucesso'
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        'Erro ao remover conciliação',
        'Tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsRemoveConciliationModalOpen(false);
    }
  };

  return (
    <>
      {loading && <PureLoader message="Carregando dados..." />}
      <FormBillToReceive
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
        titleId={titleId}
        cancel={handleCancel}
        setIsRemoveConciliationModalOpen={setIsRemoveConciliationModalOpen}
      />

      {/* Bank slip modals */}
      <BankSlipUpdateConfirmModal
        show={showUpdateBankSlipConfirmModal}
        values={useSelector((state) => state.form.billToReceive?.values)}
        onSubmit={handleSubmit}
      />

      {bankSlipErrorsModal && (
        <BankSlipErrorsModal
          show={bankSlipErrorsModal}
          errors={bankSlipErrors}
          statusCode={bankSlipErrorStatus}
          onCancel={() => setBankSlipErrorsModal(false)}
        />
      )}

      <Modal
        show={isRemoveConciliationModalOpen}
        onHide={() => setIsRemoveConciliationModalOpen(false)}
        dialogClassName="modal-40w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Remover Conciliação</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <strong style={{ fontSize: '14px' }}>
              Você tem certeza que deseja remover a conciliação?
            </strong>
            <p style={{ fontSize: '14px' }}>
              O processo é irreversível e ao remover a conciliação, o vínculo
              será removido. Sendo obrigatório refazer novamente.
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex end gap-050">
            <button
              className="button button-h35 button-red"
              onClick={() => setIsRemoveConciliationModalOpen(false)}
            >
              Não
            </button>
            <button
              className="button button-h35 button-green"
              onClick={handleRemoveBankConciliation}
            >
              Remover Conciliação
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Bills alerts */}
      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        onSubmit={() => history.push(constants.ROUTES.BILLS_TO_RECEIVE)}
        message="Deseja realmente sair do título? Está ação não salvará qualquer alteração realizada"
      />

      <AlertModal
        show={isCancelBillModalOpen}
        onHide={() => setIsCancelBillModalOpen(false)}
        onCancel={() => setIsCancelBillModalOpen(false)}
        onSubmit={() => handleConfirmModal(constants.BILLS_STATUS.CANCELED)}
        message="Deseja cancelar o título? Esta ação não salvará as informações apresentadas na tela"
      />

      <LowBillReceiveShowModal
        isOpen={isLowBillModalOpen}
        handleClose={() => setIsLowBillModalOpen(false)}
        handleSubmit={handleConfirmLowBill}
      />

      {isSaveModalOpen && (
        <SaveBillModal
          isOpen={isSaveModalOpen}
          bill={pendingBillValues || bill}
          handleClose={() => {
            setIsSaveModalOpen(false);
            setPendingBillValues(null);
          }}
          handleSubmit={handleConfirmModal}
        />
      )}
    </>
  );
}

BundleFormBillToReceive = reduxForm({
  form: 'billToReceive',
  enableReinitialize: true,
})(BundleFormBillToReceive);
function mapStateToProps(state) {
  return { billToReceive: state.form.billToReceive?.values };
}
export default connect(mapStateToProps)(withRouter(BundleFormBillToReceive));
