import { useRecoilState } from 'recoil';
import AlertRepository from 'repositories/Alert';
import BoletoFlexAlertRepository from 'repositories/BoletoFlexAlert';
import MaintenanceAlertRepository from 'repositories/MaintenanceAlert';
import KoinAlertRepository from 'repositories/KoinAlert';
import {
  ALL_ALERTS_STATE,
  ALERTS_COUNT_STATE,
  NOT_VISUALIZED_ALERTS_STATE,
} from 'v2/store/AlertPopoverStore';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { useModulesContext } from 'contexts/modules';
import { useHistory } from 'react-router';
import { useState } from 'react';
import useWebSocket from './useWebSocket';
import { useEffect } from 'react';
import { BudgetAlertRepository } from 'v2/repositories/BudgetAlertRepository';
export function useAlerts() {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { company, companyId } = useAuth();
  const { webSocket, isConnected } = useWebSocket();
  const [allAlerts, setAllAlerts] = useRecoilState(ALL_ALERTS_STATE);
  const [alertsCount, setAlertsCount] = useRecoilState(ALERTS_COUNT_STATE);
  const [isBlinkingAlerts, setIsBlinkingAlerts] = useRecoilState(
    NOT_VISUALIZED_ALERTS_STATE
  );
  const { isWorkshopPanelModuleActive } = useModulesContext();
  const history = useHistory();
  const [showMore, setShowMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  //TODO: CRIAR UM HELPER PARA CONTROLE DO WEBSOCKET JUNTAMENTE COM TRATAMENTO
  // DOS STATUS
  const fetchWebSocket = async (cpfCnpj) => {
    if (webSocket.onopen === webSocket.OPEN) {
      return;
    }
    const data = {
      type: 'identify',
      clientId: cpfCnpj,
    };
    webSocket.send(JSON.stringify(data));
  };

  const handleGetAlertsCount = async (companyId) => {
    try {
      const data = {
        type: 'getAlertsCount',
        companyId: companyId,
      };

      return webSocket.send(JSON.stringify(data));
    } catch (err) {
      return err;
    }
  };

  const handleWebSocketStatus = (data) => {
    const resStatus = data.status;

    switch (resStatus.toUpperCase()) {
      case 'AUTHENTICATED':
        return handleGetAlertsCount(companyId);
      case 'ALERTS':
        if (data.count > alertsCount) {
          setIsBlinkingAlerts(true);
        }
        return setAlertsCount(data.count);
      default:
        return console.error(data);
    }
  };

  useEffect(() => {
    const cpfCnpj = company?.Cpf_Cnpj;
    if (cpfCnpj && isConnected) {
      fetchWebSocket(cpfCnpj);
    }
  }, [isConnected, company]);

  useEffect(() => {
    if (isConnected && webSocket.onmessage && companyId) {
      webSocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        handleWebSocketStatus(message);
      };
    }
  }, [webSocket, isConnected, companyId]);

  const getAlerts = async (companyId, isNew) => {
    try {
      setIsLoading(true);
      const { data, total } = await AlertRepository.getAllByCompanyId({
        companyId,
        limit: 10,
        page: isNew ? 1 : page,
        showMaintenance: isWorkshopPanelModuleActive,
      });

      setShowMore(total > [...allAlerts, ...data].length);
      if (isNew) {
        setPage(2);
        setAllAlerts(data);
      } else {
        setPage(page + 1);
        setAllAlerts([...allAlerts, ...data]);
      }
    } catch (err) {
      console.error(err);
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  const getVisualizeAllAlerts = async (companyId) => {
    try {
      await MaintenanceAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });

      await BoletoFlexAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });

      await BudgetAlertRepository.visualizeAll(companyId);
    } catch (err) {
      console.log(err);
      return err;
    } finally {
      handleGetAlertsCount(companyId);
      resetData();
    }
  };

  const getVisualizeWorkshopPanelAlerts = async (companyId) => {
    try {
      await MaintenanceAlertRepository.visualizeAll(companyId, {
        visualizedDate: new Date(),
      });
    } catch (err) {
      return err;
    } finally {
      getAlerts();
      handleGetAlertsCount(companyId);
    }
  };

  const handleVisualizeAlert = async (id, type) => {
    try {
      switch (type) {
        case 'MAINTENANCE':
          await MaintenanceAlertRepository.update(id, {
            visualizedDate: new Date(),
          });
          break;
        case 'BOLETO_FLEX':
          await BoletoFlexAlertRepository.update(id, {
            visualizedDate: new Date(),
          });
          break;
        case 'KOIN':
          await KoinAlertRepository.update(id);
          break;
        case 'BUDGET':
          await BudgetAlertRepository.update(id, {
            visualizedDate: new Date(),
          });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
      toastr.error('Falha ao atualizar alerta', 'Por favor, tente novamente');
    } finally {
      await getAlerts(companyId);
      handleGetAlertsCount(companyId);
    }
  };

  const resetData = () => {
    setPage(1);
    setAllAlerts([]);
    setShowMore(true);
    setShowSubMenu(false);
  };

  const handleVisualizeAllAlerts = async () => {
    try {
      await getVisualizeAllAlerts(companyId);
      resetData();
    } catch (err) {
      toastr.error('Erro ao atualizar os status das notificações', `${err}.`);
    }
  };

  const handleRedirectToSale = async (saleId, alertId, alertType) => {
    history.push(`sale#${saleId}`, { saleId });
    handleVisualizeAlert(alertId, alertType);
    setShowSubMenu(false);
  };

  return {
    showSubMenu,
    resetData,
    setShowSubMenu,
    alertsCount,
    allAlerts,
    getAlerts,
    isBlinkingAlerts,
    setIsBlinkingAlerts,
    getVisualizeAllAlerts,
    getVisualizeWorkshopPanelAlerts,
    handleVisualizeAlert,
    handleRedirectToSale,
    handleVisualizeAllAlerts,
    isLoading,
    showMore,
    setAllAlerts,
  };
}
