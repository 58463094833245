import React, { useMemo, useState } from 'react';
import { change } from 'redux-form';
import AlertModal from 'components/AlertModal/AlertModal';
import { Modal } from 'react-bootstrap';

import FormModalFinancialMovement from 'client/components/FormModals/FinancialMovement';
import Button from 'client/components/CustomButton/CustomButton';

import './index.css';
import { useAuth } from 'contexts/auth';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { toastr } from 'react-redux-toastr';

export default function DevolutionOptionsModal({
  isOpen,
  setIsDevolutionOptionsModalOpen,
  dispatch,
  customerId,
  handleReturnItems,
  handleDevolutionWithCreditGeneration,
  handleCalculateReturnedProductValues,
}) {
  const { company } = useAuth();
  const { isPlanPrime } = usePlanSignatureContext();

  const devolutionOptions = useMemo(
    () => [
      {
        description: 'Apenas Devolver Estoque',
        backgroundColor: '#428bca',
        onClick: () => handleReturnItems(),
      },
      isPlanPrime && company?.companyConfig?.manageInternalCredit
        ? {
            description: 'Gerar Crédito para Cliente',
            backgroundColor: '#5cb85c',
            onClick: () => {
              if (customerId === 583)
                return toastr.warning(
                  'Importante',
                  'Cliente Consumidor não trabalha com crédito da loja. Faça a identificação do cliente e tente novamente.'
                );
              setIsGenerateCreditConfirmationModalOpen(true);
            },
          }
        : {},
      {
        description: 'Gerar Devolução de Valor',
        backgroundColor: '#f0ad4e',
        onClick: () => handleOpenFinancialMovementModal(),
      },
    ],
    []
  );

  const [
    isGeneratedCreditConfirmationModalOpen,
    setIsGenerateCreditConfirmationModalOpen,
  ] = useState(false);

  const [isFinancialMovementModalOpen, setIsFinancialMovementModalOpen] =
    useState(false);

  const handleOpenFinancialMovementModal = () => {
    setIsFinancialMovementModalOpen(true);

    const devolutionValue = handleCalculateReturnedProductValues();

    setTimeout(() => {
      dispatch(change('FinancialMovement', 'value', devolutionValue));
      dispatch(change('FinancialMovement', 'isDevolution', true));
      dispatch(change('FinancialMovement', 'finality', '2'));
    }, 500);
  };

  return (
    <>
      <Modal
        show={isOpen}
        animation={true}
        onHide={() => setIsDevolutionOptionsModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="devolution-options-modal__body">
            <p>
              Ao realizar uma devolução, você poderá selecionar de que forma o
              valor da devolução será devolvido. Escolha uma opção abaixo:
            </p>
            <div className="devolution-options-modal__buttons">
              {devolutionOptions
                .filter((option) => !!option.description)
                .map((option, index) => (
                  <Button
                    key={index}
                    style={{
                      backgroundColor: option.backgroundColor,
                    }}
                    fill
                    onClick={option.onClick}
                  >
                    {option.description}
                  </Button>
                ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isGeneratedCreditConfirmationModalOpen && (
        <AlertModal
          show={true}
          message={
            <>
              <span>
                <strong>
                  Você tem certeza que deseja gerar crédito dessa devolução?
                </strong>
              </span>
              <br />
              <span>
                A partir do momento que for devolvido, o valor de crédito será
                gerado e o cliente poderá usar para o pagamento e/ou compras na
                loja.
              </span>
            </>
          }
          onHide={() => setIsGenerateCreditConfirmationModalOpen(false)}
          onCancel={() => setIsGenerateCreditConfirmationModalOpen(false)}
          onSubmit={() => {
            setIsGenerateCreditConfirmationModalOpen(false);
            handleDevolutionWithCreditGeneration();
          }}
        />
      )}

      {isFinancialMovementModalOpen && (
        <FormModalFinancialMovement
          preventClose
          onCancel={() => setIsFinancialMovementModalOpen(false)}
          onSubmit={() =>
            handleReturnItems(
              `Foi realizada a devolução de estoque e gerada a movimentação de valores`
            )
          }
        />
      )}
    </>
  );
}
