import React, { useEffect, useState } from 'react';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from 'contexts/auth';
import { useAlerts } from 'v2/hooks/useAlerts';

import './index.css';
import { Container } from './AlertPopover.styles';
import { AlertMessages } from './AlertMessages';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';

function AlertPopover() {
  const {
    alertsCount,
    allAlerts,
    getAlerts,
    getVisualizeAllAlerts,
    handleVisualizeAlert,
    isBlinkingAlerts,
    setIsBlinkingAlerts,
    showSubMenu,
    setShowSubMenu,
    handleVisualizeAllAlerts,
    handleRedirectToSale,
    isLoading,
    showMore,
    resetData,
    setAllAlerts,
  } = useAlerts();
  const { companyId } = useAuth();

  useEffect(() => {
    if (alertsCount > 0 && showSubMenu) loadAlerts(true);
  }, [alertsCount, showSubMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const popoverElement = document.querySelector('.alert-popover');
      if (popoverElement && !popoverElement.contains(event.target)) {
        const subMenuElement = document.querySelector(
          '.header-sub-menu-alert-content'
        );
        if (subMenuElement) {
          subMenuElement.scrollTop = 0;
        }
        resetData();
      }
    };

    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        loadAlerts();
      }
    };

    const subMenuElement = document.querySelector(
      '.header-sub-menu-alert-content'
    );
    if (showSubMenu) {
      document.addEventListener('mousedown', handleClickOutside);
      if (subMenuElement) {
        subMenuElement.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (subMenuElement) {
        subMenuElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showSubMenu, allAlerts]);

  const loadAlerts = async (isNew) => {
    if (!showMore) return;
    else {
      await getAlerts(companyId, isNew);
    }
  };

  const getAlertsLabel = (count) => {
    switch (count) {
      case 0:
        return '0 Novas Mensagens';
      case 1:
        return '1 Nova Mensagem';
      default:
        return `${count} Novas Mensagens`;
    }
  };

  return (
    <div className="alert-popover">
      <Container
        isBlinking={isBlinkingAlerts}
        count={alertsCount}
        onClick={() => {
          setShowSubMenu(!showSubMenu);
          setIsBlinkingAlerts(false);
        }}
      >
        <div style={{ width: '18px', height: '18px', flexShrink: 0 }}>
          <FontAwesomeIcon icon={faBell} fontSize={12} cursor="pointer" />
        </div>
        <span style={{ whiteSpace: 'nowrap' }}>
          {getAlertsLabel(alertsCount)}
        </span>
      </Container>

      {showSubMenu && (
        <div className="header-sub-menu-alert-content">
          {allAlerts?.length === 0 ? (
            isLoading ? (
              <LoadingSpinnerFullHeight />
            ) : (
              <div className="flex center align-center p-1">
                <span className="no-notifications-message">
                  Você não possui notificações.
                </span>
              </div>
            )
          ) : (
            <AlertMessages
              alerts={allAlerts}
              loadAlerts={loadAlerts}
              handleVisualizeAlert={handleVisualizeAlert}
              handleVisualizeAllAlerts={handleVisualizeAllAlerts}
              handleRedirectToSale={handleRedirectToSale}
              setAllAlerts={setAllAlerts}
            />
          )}
          <span
            className={`${isLoading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
          />
        </div>
      )}
    </div>
  );
}

export default AlertPopover;
