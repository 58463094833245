import React from 'react';

import { faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

export const AlertMessages = ({
  alerts,
  handleVisualizeAlert,
  handleVisualizeAllAlerts,
  handleRedirectToSale,
  setAllAlerts,
}) => {
  const calculateTimeDifference = (createdAt) => {
    const now = new Date();
    const alertTime = new Date(createdAt);
    const diffInMs = now - alertTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} dia${diffInDays > 1 ? 's' : ''}`;
    }

    if (diffInHours > 0) {
      const remainingMinutes = diffInMinutes % 60;
      return `${diffInHours} hora${
        diffInHours > 1 ? 's' : ''
      } e ${remainingMinutes} minuto${remainingMinutes !== 1 ? 's' : ''}`;
    }

    return `${diffInMinutes || 1} minuto${diffInMinutes > 1 ? 's' : ''}`;
  };
  const handleRemoveAlert = async (alertId, alertType) => {
    await handleVisualizeAlert(alertId, alertType);
    setAllAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alert.id !== alertId)
    );
  };
  const renderDetailAlertData = (alert) => {
    return (
      <div
        className="flex column start align-start"
        style={{ width: '310px', cursor: 'pointer' }}
        onClick={() => handleRedirectToSale(alert.saleId, alert.id, alert.type)}
        alert
      >
        <span
          style={{
            color: 'black',
            fontSize: '12px',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            overflow: 'visible',
            width: '100%',
          }}
        >
          {alert.message || 'Sem Mensagem'}
        </span>
        <div className="flex between w-100">
          <strong style={{ color: 'black', fontSize: '12px' }}>
            Venda/O.S: {alert.saleCode || 'Sem Código'}
          </strong>
          <span
            style={{
              color: 'black',
              fontSize: '12px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FontAwesomeIcon
              icon={faClock}
              size="md"
              style={{ color: 'gray' }}
            />
            <span
              style={{
                color: 'black',
                fontSize: '12px',
              }}
            >
              {calculateTimeDifference(alert.createdAt)}
            </span>
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="flex column w-100">
      <div
        className="flex center align-center w-100 p-050"
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        {alerts?.length > 0 && (
          <a
            className="pointer button__hyperlink-style"
            onClick={handleVisualizeAllAlerts}
          >
            Marcar todos como lidos
          </a>
        )}
      </div>
      {alerts?.map((alert) => {
        return (
          <div>
            <hr
              style={{
                backgroundColor: 'black',
                width: '100%',
                marginTop: '0px',
                marginBottom: '0px',
              }}
            />
            <div className="flex row start align-center w-100 h-50 ml-05">
              <FontAwesomeIcon
                icon={faEnvelope}
                size="md"
                style={{ color: '#337ab7' }}
              />
              {renderDetailAlertData(alert)}
              <span
                style={{
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  width: '30px',
                  height: '100%',
                }}
              >
                <FontAwesomeIcon
                  onClick={() => handleRemoveAlert(alert.id, alert.type)}
                  icon={faX}
                  size="sm"
                  style={{ color: 'gray', cursor: 'pointer' }}
                />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
