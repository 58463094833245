import { useRecoilState } from 'recoil';
import { INACTIVE_FISCAL_MODULE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { INACTIVE_FISCAL_MODULE_PLAN_VARIATION_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { CRM_MODULE_WITHOUT_PLAN_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { CRM_MODULE_WITH_PLAN_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { VIDEO_MODULE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_GESTAO_PATIO_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { GESTAO_PATIO_MODULE_WITHOUT_PLAN_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_VIDEO_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_CRM_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { BLOCK_SEGMENTS_MODAL_OPEN } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_FISCAL_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { INACTIVE_MODULE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { FEATURE_NOT_AVAILABLE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';

export function useBlockModals() {
  const [isFeatureNotAvailableModalOpen, setIsFeatureNotAvailableModalOpen] =
    useRecoilState(FEATURE_NOT_AVAILABLE_MODAL_STATE);

  const [isModuleInactiveModalOpen, setIsModuleInactiveModalOpen] =
    useRecoilState(INACTIVE_MODULE_MODAL_STATE);

  const [currentInactiveModule, setCurrentInactiveModule] = useRecoilState(
    CURRENT_INACTIVE_MODULE
  );

  const [currentInactiveFiscalModule, setCurrentInactiveFiscalModule] =
    useRecoilState(CURRENT_INACTIVE_FISCAL_MODULE);

  const [isFiscalModuleInactiveModalOpen, setIsFiscalModuleInactiveModalOpen] =
    useRecoilState(INACTIVE_FISCAL_MODULE_MODAL_STATE);

  const [
    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,
  ] = useRecoilState(INACTIVE_FISCAL_MODULE_PLAN_VARIATION_MODAL_STATE);

  const [isSegmentsBlockedModalOpen, setIsSegmentsBlockedModalOpen] =
    useRecoilState(BLOCK_SEGMENTS_MODAL_OPEN);

  const [
    isCrmBlockedModuleWithoutPlanModalOpen,
    setIsCrmBlockedModuleWithoutPlanModalOpen,
  ] = useRecoilState(CRM_MODULE_WITHOUT_PLAN_MODAL_STATE);
  const [
    isYardManagementBlockedModuleWithoutPlanModalOpen,
    setIsYardManagementBlockedModuleWithoutPlanModalOpen,
  ] = useRecoilState(GESTAO_PATIO_MODULE_WITHOUT_PLAN_MODAL_STATE);

  const [
    isCrmBlockedModuleWithPlanModalOpen,
    setIsCrmBlockedModuleWithPlanModalOpen,
  ] = useRecoilState(CRM_MODULE_WITH_PLAN_MODAL_STATE);

  const [currentInactiveCrmModule, setCurrentInactiveCrmModule] =
    useRecoilState(CURRENT_INACTIVE_CRM_MODULE);
  const [
    currentInactiveYardManagementModule,
    setCurrentInactiveYardManagementModule,
  ] = useRecoilState(CURRENT_INACTIVE_GESTAO_PATIO_MODULE);

  const [isVideoModalOpen, setIsVideoModalOpen] = useRecoilState(
    VIDEO_MODULE_MODAL_STATE
  );

  const [currentInactiveVideoModule, setCurrentInactiveVideoModule] =
    useRecoilState(CURRENT_INACTIVE_VIDEO_MODULE);

  return {
    isFeatureNotAvailableModalOpen,
    setIsFeatureNotAvailableModalOpen,

    isModuleInactiveModalOpen,
    setIsModuleInactiveModalOpen,

    currentInactiveModule,
    setCurrentInactiveModule,

    currentInactiveFiscalModule,
    setCurrentInactiveFiscalModule,

    isFiscalModuleInactiveModalOpen,
    setIsFiscalModuleInactiveModalOpen,

    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,

    isSegmentsBlockedModalOpen,
    setIsSegmentsBlockedModalOpen,

    isCrmBlockedModuleWithoutPlanModalOpen,
    setIsCrmBlockedModuleWithoutPlanModalOpen,

    isCrmBlockedModuleWithPlanModalOpen,
    setIsCrmBlockedModuleWithPlanModalOpen,

    currentInactiveCrmModule,
    setCurrentInactiveCrmModule,

    isVideoModalOpen,
    setIsVideoModalOpen,

    currentInactiveVideoModule,
    setCurrentInactiveVideoModule,

    isYardManagementBlockedModuleWithoutPlanModalOpen,
    setIsYardManagementBlockedModuleWithoutPlanModalOpen,

    currentInactiveYardManagementModule,
    setCurrentInactiveYardManagementModule,
  };
}
