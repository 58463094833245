import React from 'react';
import { format, subDays } from 'date-fns';
import { useBranding } from 'hooks/useBranding';
import { toastr } from 'react-redux-toastr';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { IdeasWallRepository } from 'v2/repositories/IdeasWallRepository';

import {
  faCheck,
  faEyeSlash,
  faLightbulb,
  faThumbsUp,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { formatIdeaWallDataSheet } from '../utils';
export const useIdeaWall = () => {
  const { setIsLoading, isLoading } = useLoading();
  const { loadPlansByBranding } = useBranding();

  const infoCardsData = [
    {
      icon: faLightbulb,
      primaryText: 0,
      secondaryText: (
        <>
          Ideias <strong>Novas</strong>
        </>
      ),
      containerStyle: { background: '#5BC0DE' },
      iconStyle: { background: '#46B8DA' },

      status: 'Nova Ideia',
    },
    {
      icon: faEyeSlash,
      primaryText: 0,
      containerStyle: { background: '#2C3F50' },
      iconStyle: { background: '#142A3D' },
      secondaryText: (
        <>
          Ideias <strong>Removidas/Ocultas</strong>
        </>
      ),

      status: 'Ideia Oculta',
    },
    {
      icon: faX,
      primaryText: 0,
      secondaryText: (
        <>
          Ideias <strong>Rejeitadas</strong>
        </>
      ),
      containerStyle: { background: '#D9534F' },
      iconStyle: { background: '#D43F3A' },
      status: 'Ideia Rejeitada',
    },
    {
      icon: faThumbsUp,
      primaryText: 0,
      secondaryText: (
        <>
          Ideias <strong>Aprovadas</strong>
        </>
      ),
      containerStyle: { background: '#428BCA' },
      iconStyle: { background: '#357EBD' },
      status: 'Ideia Aprovada',
    },
    {
      icon: faCheck,
      primaryText: 0,
      secondaryText: (
        <>
          Ideias <strong>Entregues</strong>
        </>
      ),
      containerStyle: { background: '#5CB85C' },
      iconStyle: { background: '#4CAE4C' },
      status: 'Ideia Entregue',
    },
  ];
  const formatData = (dataArray) => {
    if (!dataArray) return;
    return dataArray?.some((item) => item.value === '*')
      ? ''
      : dataArray.map((item) => item.value).join(',');
  };

  const formatOptions = (dataArray) => {
    if (!dataArray || !Array.isArray(dataArray)) return [];

    const formattedOptions = dataArray.map((option) => ({
      value: option.id,
      label: option.description,
    }));

    formattedOptions.unshift({
      label: 'Todos',
      value: '*',
    });

    return formattedOptions;
  };
  const dateTypes = [
    { label: 'Criação', value: 'Criação' },
    { label: 'Aprovação', value: 'Aprovação' },
    { label: 'Rejeição/Ocultação', value: 'Rejeição/Ocultação' },
    { label: 'Entrega', value: 'Entrega' },
  ];

  const searchByOptions = [
    { label: 'Cliente', value: 'Cliente' },
    { label: 'Ideia', value: 'Ideia' },
  ];

  const orderByOptions = [
    { label: 'Mais Votadas', value: 'Mais Votadas' },
    { label: 'Mais Antigas', value: 'Mais Antigas' },
    { label: 'Mais Novas', value: 'Mais Novas' },
  ];

  const today = new Date();
  const initialStartDate = format(subDays(today, 7), 'yyyy-MM-dd');
  const initialEndDate = format(today, 'yyyy-MM-dd');

  const getIdeasWallData = async (setValue, getValues, type) => {
    const {
      initialDate,
      finalDate,
      dateType,
      selectedPlans,
      selectedBranding,
      query,
      controlTable,
      status,
      orderBy,
      category,
      subCategory,
      searchBy,
    } = getValues();

    const filters = {
      type,
      dataType: dateType.value,
      initialDate,
      finalDate,
      searchBy: searchBy.value,
      dateType: dateType.value,
      plans: formatData(selectedPlans),
      branding: formatData(selectedBranding),
      status: formatData(status),
      query,
      page: controlTable.page,
      category: category ? formatData(category) : '',
      subCategory: category ? formatData(subCategory) : '',
      limit: controlTable.pageLimit,
    };

    try {
      setIsLoading(true);

      const ideasWallData = await IdeasWallRepository.getSearchIdeasWall(
        filters
      );

      if (ideasWallData.status === 204) {
        toastr.success('Nenhuma informação encontrada.');
        setValue('spreadsheet', []);
        setValue('onboardingDataTable', []);
        return;
      }

      if (type === 'sheets') {
        const format = formatIdeaWallDataSheet(ideasWallData.ideas);
        return setValue('spreadsheet', format);
      }

      setValue('ideasWallDataTable', ideasWallData);
      setValue(
        'controlTable.totalPages',
        Math.ceil(ideasWallData.count / controlTable.pageLimit)
      );
      return ideasWallData;
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados.');
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoriesOptions = async (setValue) => {
    const categories = await IdeasWallRepository.getIdeasWallCategories();
    const formattedCategories = formatOptions(categories?.data?.rows);
    setValue('categoriesOptions', formattedCategories);
  };

  const getStatusOptions = async (setValue) => {
    const status = await IdeasWallRepository.getIdeasWallStatus();

    const formattedStatus = formatOptions(status?.data?.rows);

    setValue('statusOptions', formattedStatus);
  };

  const getSubCategories = async (categoryIds, setValue) => {
    if (!categoryIds || categoryIds.length === 0) {
      setValue('subCategoriesOptions', []);
      setValue('subCategory', []);
      toastr.warning('Selecione uma categoria para filtrar as subcategorias.');
      return;
    }
    const ids = categoryIds
      ?.filter((category) => category.value !== '*')
      .map((category) => category.value)
      .join(',');

    const subCategories = await IdeasWallRepository.getIdeasWallSubCategories(
      ids
    );
    setValue('subCategoriesOptions', formatOptions(subCategories?.data?.rows));
  };

  const getPlans = async (setValue) => {
    const plans = await loadPlansByBranding();

    setValue('plans', plans);
    return plans;
  };

  const getOptionsPlans = (plans, brandingArray) => {
    const serializedBrandingPlans = plans
      ?.filter((brandingPlan) =>
        brandingArray?.length
          ? brandingArray.some((branding) => brandingPlan.id === branding.value)
          : true
      )
      .reduce((prev, curr) => [...prev, ...curr.Plans], [])
      .map((option) => ({
        value: option.id,
        label: option.description,
      }));

    return serializedBrandingPlans;
  };

  const populateInfoCardsData = (ideasWallData) => {
    return infoCardsData.map((card) => {
      const matchingStatus = ideasWallData?.header?.find(
        (item) => item.statusDescription === card.status
      );

      return {
        ...card,
        primaryText: matchingStatus ? matchingStatus.total : 0,
      };
    });
  };
  const handleMessage = (status, isError = false) => {
    if (isError) {
      switch (status) {
        case 1:
          return 'Erro ao aprovar a ideia.';
        case 2:
          return 'Erro ao entregar a ideia.';
        case 4:
          return 'Erro ao rejeitar a ideia.';
        case 5:
          return 'Erro ao ocultar a ideia.';
        default:
          return 'Ocorreu um erro desconhecido.';
      }
    }

    switch (status) {
      case 1:
        return 'Ideia aprovada com sucesso!';
      case 2:
        return 'Ideia entregue com sucesso!';
      case 4:
        return 'Ideia rejeitada com sucesso!';
      case 5:
        return 'Ideia ocultada com sucesso!';
      default:
        return '';
    }
  };

  const updateIdeaWallData = async (postId, statusId, adminId, getValues) => {
    const { title, feedback, category, subCategory, body } = getValues(
      'ideaWallForm.updateForm'
    );

    const updateData = {
      title,
      statusId,
      adminId,
      comment: feedback,
      body,
      categoryId: category.value,
      subCategoryId: subCategory.value,
    };
    try {
      setIsLoading(true);
      await IdeasWallRepository.updateIdeaWall(postId, updateData);
      toastr.success(handleMessage(statusId));
    } catch (error) {
      toastr.error(handleMessage(statusId, true));
      console.error('Erro ao atualizar o Idea Wall:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    dateTypes,
    initialStartDate,
    initialEndDate,
    searchByOptions,
    orderByOptions,
    getOptionsPlans,
    getPlans,
    getIdeasWallData,
    isLoading,
    getCategoriesOptions,
    getSubCategories,
    getStatusOptions,
    populateInfoCardsData,
    updateIdeaWallData,
    formatOptions,
    infoCardsData,
  };
};
