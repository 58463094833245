import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Kanban } from 'components/Kanban';
import constants from 'utils/constants';
import { YardManagementRepository } from 'v2/repositories/YardManagementRepository';
import { KanbanStatusModal } from 'components/Kanban/KanbanCard/StatusModal';
import { useFormContext } from 'react-hook-form';
import { useYardManagement } from '../hooks/useYardManagement';
import { useKanbanYardManagement } from '../hooks/useKanbanYardManagement';
export function KanbanYardManagement() {
  const [columns, setColumns] = useState([]);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragColumn = useRef();
  const [isDraggingCard, setIsDraggingCard] = useState(false);
  const { handleColumns, deleteColumn } = useYardManagement();
  const {
    handleColumnDragStart,
    handleColumnDragOver,
    handleColumnDrop,
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
  } = useKanbanYardManagement();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const { watch } = useFormContext();

  const showFinished = watch('showFinished');
  const showType = watch('model');

  const getColumns = async () => {
    const columns = await handleColumns(showType.value, showFinished);
    setColumns(columns);
  };
  const dragEnd = () => {
    handleDragEnd(
      columns,
      setColumns,
      setIsDraggingCard,
      dragItem,
      dragOverItem,
      getColumns
    );
  };

  const handleDeleteColumn = async (id) => {
    await deleteColumn(id);
    getColumns();
    setIsStatusModalOpen(false);
  };

  useEffect(() => {
    getColumns();
  }, [showFinished, showType]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        gap: '15px',
        overflowX: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          {columns.length
            ? columns.map((column, columnIndex) => (
                <Kanban.Root
                  draggable
                  onDragStart={(e) => {
                    handleColumnDragStart(
                      dragColumn,
                      isDraggingCard,
                      e,
                      columnIndex
                    );
                  }}
                  onDragOver={handleColumnDragOver}
                  onDrop={(e) =>
                    handleColumnDrop(
                      e,
                      isDraggingCard,
                      dragColumn,
                      columns,
                      setColumns,
                      columnIndex
                    )
                  }
                >
                  <Kanban.StatusHeader
                    name={column.name}
                    color={column.color}
                    total={column.cards.length}
                    haveOptionsButton
                    columnId={column.id}
                    handleSearchScheduling={() => {}}
                    updateColumn={YardManagementRepository.updateColumn}
                    deleteColumn={() => {
                      handleDeleteColumn(column.id);
                    }}
                    onSuccess={() => getColumns()}
                    blockColumn={2}
                    messageModal="cards listados"
                  />
                  <Kanban.NewScheduling
                    buttonTitle="Novo Orçamento/OS"
                    description={column.name}
                    urlRedirect={`${constants.ROUTES.SALE}?kanbanYard=true&statusColumn=${column.name}`}
                  />
                  <div
                    className="flex column gap-025"
                    style={{ height: '100%', gap: '1rem' }}
                    onDragEnter={(e) =>
                      handleDragEnter(
                        e,
                        dragItem,
                        columnIndex,
                        column.cards.length
                      )
                    }
                  >
                    {column.cards.length
                      ? column.cards.map((card, cardIndex) => (
                          <Kanban.Card
                            column={columns[columnIndex]}
                            key={card.id}
                            id={card.id}
                            card={card}
                            startScheduling={card.Date}
                            draggable
                            onDragStart={(e) =>
                              handleDragStart(
                                e,
                                cardIndex,
                                columns,
                                setIsDraggingCard,
                                dragItem,
                                columnIndex
                              )
                            }
                            onDragEnd={dragEnd}
                            handleSearchScheduling={() => {}}
                            tagDirection="column"
                            tagAlign="center"
                            urlDirectory={`${constants.ROUTES.SALE}#${card.id}?kanbanYard=true&statusColumn=${column.name}`}
                          />
                        ))
                      : null}
                  </div>
                </Kanban.Root>
              ))
            : null}
        </div>
        <button
          type="button"
          style={{
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '5px',
            padding: '10px',
            color: '#FFFFFF',
            borderRadius: '5px',
            backgroundColor: '#4E4B49',
            width: '200px',
            height: '35px',
            cursor: 'pointer',
          }}
          onClick={() => setIsStatusModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          Adicionar nova coluna
        </button>
      </div>
      {isStatusModalOpen ? (
        <KanbanStatusModal
          show={isStatusModalOpen}
          onCancel={() => setIsStatusModalOpen(false)}
          createColumn={YardManagementRepository.createColumn}
          onSuccess={() => {
            getColumns();
            setIsStatusModalOpen(false);
          }}
        />
      ) : null}
    </div>
  );
}
