import React from 'react';
import { KanbanCardHeader } from './KanbanCardHeader';
import { KanbanCardBody } from './KanbanCardBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AlertModal from 'components/AlertModal/AlertModal';

export const KanbanCard = ({
  id,
  draggable,
  onDragStart,
  onDragEnd,
  card,
  column,
  handleSearchScheduling,
  tagDirection,
  tagAlign,
  urlDirectory,
  hasDeleteCard,
}) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  return (
    <section
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      style={{
        width: '100%',

        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #CACACA',
        backgroundColor: 'white',
        cursor: 'move',
      }}
    >
      <div
        style={{
          width: '98%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #0000001A',
          borderRadius: '4px',
          fontSize: '9px',
          boxShadow: '0px 3px 2px rgba(35, 35, 35, 0.07)',
          border: '1px solid #0000001A !important',
          gap: '5px',
        }}
      >
        <div
          style={{
            width: '5px',
            height: '100%',
            backgroundColor: `${column.color}`,
            borderRight: '1px solid #0000001A',
            borderRadius: '4px 0 0 4px',
          }}
        />
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'space-between',
          }}
        >
          <KanbanCardHeader card={card} urlDirectory={urlDirectory} />
          <KanbanCardBody
            id={id}
            customerName={card.customerName}
            customerPhone={card.customerPhone}
            startScheduling={card.Date}
            endScheduling={card.endScheduling}
            boxDescription={card.PrismaBox}
            handleSearchScheduling={handleSearchScheduling}
            tagDirection={tagDirection}
            tagAlign={tagAlign}
          />

          <footer
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'end',
              color: '#D9534F',
              fontSize: '7px',
              padding: '5px',
            }}
          >
            {hasDeleteCard ? (
              <a
                href="#"
                style={{
                  color: '#D9534F',
                  display: 'flex',
                  alignItems: 'end',
                  gap: '5px',
                  flexDirection: 'row',
                  textDecoration: 'underline',
                  fontSize: '9px',
                }}
                onClick={() => setConfirmDelete(true)}
              >
                Excluir
                <FontAwesomeIcon
                  style={{ width: '14px', height: '14px', color: '#D9534F' }}
                  icon={faTrash}
                />
              </a>
            ) : null}
          </footer>
        </div>
        <AlertModal
          show={confirmDelete}
          onHide={() => setConfirmDelete(false)}
          onCancel={() => setConfirmDelete(false)}
          title="Exclusão de Agendamento"
          subtitle="Você tem certeza que deseja excluir o agendamento?"
          message="O processo é irreversível. Uma vez excluído, será necessário criar um novo agendamento para que seja exibido."
          onSubmit={() => {}}
        />
      </div>
    </section>
  );
};
