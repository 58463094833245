import { formatToPhone } from 'brazilian-values';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
export const getBreadcrumbData = (isEditing) => {
  return {
    data: ['Início', 'Mural de Ideias'],
    path: ['/admin/home', null],
  };
};

const validateData = (value) => value || '-';

export const formatIdeaWallDataSheet = (data) => {
  return data.map((item) => ({
    createdAt: item.createdAt
      ? format(new Date(item.createdAt), 'dd/MM/yyyy')
      : '-',
    refusedAt: item.refusedAt
      ? format(new Date(item.refusedAt), 'dd/MM/yyyy')
      : '-',
    deliveredAt: item.deliveredAt
      ? format(new Date(item.deliveredAt), 'dd/MM/yyyy')
      : '-',
    approvedAt: item.approvedAt
      ? format(new Date(item.approvedAt), 'dd/MM/yyyy')
      : '-',
    statusDescription: item.statusDescription,
    cpfCnpj: item.cpfCnpj ? cpfOrCnpjMask(item.cpfCnpj) : '-',
    companyName: validateData(item.companyName),
    razaoSocial: validateData(item.razaoSocial),
    planName: validateData(item.planName),
    brandingName: validateData(item.brandingName),
    Email: validateData(item.Email),
    phoneNumber: item.phoneNumber ? formatToPhone(item.phoneNumber) : '-',
    title: item.title,
    subCategoryDescription: item.subCategoryDescription,
    votesCount: item.votesCount,
  }));
};
