import React from 'react';
import { Modal } from 'react-bootstrap';

export function LimitExceedModal({ isOpen, handleClose }) {
  return (
    <Modal show={isOpen} onHide={handleClose} dialogClassName="modal-35w">
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <strong>Limite de formas de pagamento</strong>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: '14px', lineHeight: '1.5' }}>
          <strong>Quantidade limite de forma de pagamento atingido</strong>
          <br />
          <br />A SEFAZ limita a 120 formas de pagamentos que podem ser enviados
          na nota fiscal e atualmente a quantidade ultrapassa o limite.
          Verifique as vendas e selecione abaixo desse limite. Se necessário,
          emita mais de 1 nota fiscal.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end">
          <button
            className="button button-red button-h35 flex center"
            style={{ width: '100px' }}
            onClick={handleClose}
          >
            <span>Voltar</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
