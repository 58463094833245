export const defaultValues = {
  id: null,
  loading: false,
  header: {
    companyName: 'tradingName',
    customerName: 'tradingName',
    createdFormat: 'both',
  },
  otherDetails: {
    showEmptyDiscount: true,
    observation: true,
    defects: true,
    malfunctions: true,
  },
};

export const headerCompanyNameOptions = [
  {
    label: 'Nome Fantasia/Nome',
    value: 'tradingName',
  },
  {
    label: 'Razão Social',
    value: 'companyName',
  },
];

export const headerCustomerNameOptions = [
  {
    label: 'Nome Fantasia',
    value: 'tradingName',
  },
  {
    label: 'Razão Social',
    value: 'companyName',
  },
];

export const headerCreatedFormatOptions = [
  {
    label: 'Apenas Data de Criação',
    value: 'onlyCreatedAt',
  },
  {
    label: 'Imprimir Ambos',
    value: 'both',
  },
];

export const otherDetailsShowEmptyDiscountOptions = [
  {
    label: 'Exibir',
    value: true,
  },
  {
    label: 'Não Exibir',
    value: false,
  },
];

export const otherDetailsObservationOptions = [
  {
    label: 'Exibir',
    value: true,
  },
  {
    label: 'Não Exibir',
    value: false,
  },
];

export const otherDetailsDefectsOptions = [
  {
    label: 'Exibir',
    value: true,
  },
  {
    label: 'Não Exibir',
    value: false,
  },
];

export const otherDetailsMalfuctionsOptions = [
  {
    label: 'Exibir',
    value: true,
  },
  {
    label: 'Não Exibir',
    value: false,
  },
];
