import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AlertModal from 'components/AlertModal/AlertModal';

import { toastr } from 'react-redux-toastr';
import { KanbanStatusModal } from './KanbanCard/StatusModal';

export const KanbanStatusHeader = ({
  name,
  color,
  total,
  haveOptionsButton,
  columnId,
  updateColumn,
  createColumn,
  deleteColumn,
  onSuccess,
  blockColumn,
  messageModal,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleOptionsClick = () => {
    if (!columnId || columnId === blockColumn) {
      toastr.warning(
        'Não permitido',
        'Exclusão e alteração não são permitidos para status/colunas padrões.'
      );
    } else {
      setIsOpenModal(true);
    }
  };

  const handleDeleteColumn = async () => {
    try {
      await deleteColumn();
      setConfirmationModal(false);
      setIsOpenModal(false);

      toastr.success(
        'Status excluído com sucesso',
        ` O status/coluna foi excluído.`
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="statusHeader" className="flex w100 justify-between align-center">
      <div className="flex start w100 gap-025">
        <div
          style={{
            width: '15px',
            height: '15px',
            backgroundColor: color,
            borderRadius: '2px',
          }}
        />
        <span className="fontSize-12" style={{ color: '#444444' }}>
          {name} ({total})
        </span>
      </div>
      {haveOptionsButton && (
        <FontAwesomeIcon
          icon={faEllipsis}
          onClick={() => {
            handleOptionsClick();
          }}
          className="button-icon flex end"
          style={{ width: '10px', color: '#444444' }}
        />
      )}
      <Modal
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
        animation={true}
        dialogClassName="modal-25w"
        style={{ backgroundColor: '#EBEBEB !important' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong style={{ fontSize: '16px' }}>Ações da coluna</strong>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <button
              style={{ ...style.button }}
              onClick={() => {
                setIsOpenStatusModal(true);
              }}
            >
              Editar coluna/status
            </button>
            <button
              onClick={() => setConfirmationModal(true)}
              style={{ ...style.button, backgroundColor: '#C0C0C0' }}
            >
              Excluir coluna/status
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {isOpenStatusModal && (
        <KanbanStatusModal
          show={isOpenStatusModal}
          onCancel={() => setIsOpenStatusModal(false)}
          onSuccess={() => {
            setIsOpenStatusModal(false);
            setIsOpenModal(false);
            onSuccess();
          }}
          name={name}
          id={columnId}
          createColumn={createColumn}
          updateColumn={updateColumn}
        />
      )}

      <AlertModal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        onCancel={() => setConfirmationModal(false)}
        title="Exclusão de Coluna/Status"
        subtitle="Você tem certeza que deseja excluir a coluna/status?"
        message={`O processo é irreversível. Uma vez excluído, todos os ${messageModal} com esse status, independente do período, moverá para a coluna "Sem Status".`}
        onSubmit={handleDeleteColumn}
      />
    </div>
  );
};

const style = {
  button: {
    width: '100%',
    textAlign: 'start',
    padding: '5px',
    border: 'none',
    borderRadius: '4px',
  },
};
