import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Button from '../CustomButton/CustomButton';
import './styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { usePlanSignatureContext } from '../../../contexts/plan-signature';

function ModalBlockTax(props) {
  const { isPlanFree } = usePlanSignatureContext();

  const upgrade = () => {
    const WHATSAPP = isPlanFree
      ? 'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1,%20utilizo%20o%20plano%20free%20e%20gostaria%20de%20assinar%20o%20OS%20Digital'
      : 'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1,%20%20sou%20cliente%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20upgrade%20de%20plano%20do%20OS%20Digital';
    return window.open(WHATSAPP, '_blank');
  };

  const handleSubmit = () => {
    upgrade();
    props.onHide();
  };

  return (
    <Modal show={props.show} animation onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Função não disponível</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <span>
          <strong>Função não disponível para o seu plano.</strong>
        </span>
        <br />
        <span>
          Para ter acesso a funcionalidade você precisa solicitar o upgrade do
          seu plano, para isso, clique no botão solicitar troca de plano para
          conversar com um dos nossos consultores comerciais.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div className="upgrade-modal-footer">
          <Button bsStyle="danger" fill onClick={() => props.onHide()}>
            Voltar
          </Button>
          <Button bsStyle="info" fill onClick={handleSubmit}>
            <div>
              <FontAwesomeIcon icon={faWhatsapp} />
              <span>Solicitar troca de plano</span>
            </div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(ModalBlockTax);
