import React, { useState } from 'react';
import {
  faEdit,
  faTrashAlt,
  faPrint,
  faSearch,
  faWindowClose,
  faShareAltSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';

import { INVOICE_STATUS_DESCRIPTION } from '../../../utils/constants';
import NFCesRepository from '../../../repositories/NFCes';

import AlertModal from 'components/AlertModal/AlertModal';
import CancelInvoiceModal from './CancelInvoiceModal';
import CorrectionLetterModal from './CorrectionLetterModal';
import Loader from '../../../components/Loader';
import ShareNFCe from 'client/components/ShareNFCe/ShareNFCe.js';
import { useFilters } from 'v2/hooks/useFilters';
import { usePagination } from 'v2/hooks/usePagination';

const NFCeActions = ({ NFCe, onChange, onEdit }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);
  const [showCorrectionLetterModal, setShowCorrectionLetterModal] =
    useState(false);
  const [isLoadingDeleteInvoice, setIsLoadingDeleteInvoice] = useState(false);
  const [isShareNFCeModalOpen, setIsShareNFCeModalOpen] = useState(false);
  const [loadingConsult, setLoadingConsult] = useState(false);

  const isOpen = NFCe.status === INVOICE_STATUS_DESCRIPTION.OPEN;
  const isOpenWithErrors =
    NFCe.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS;
  const isDisabled = NFCe.status === INVOICE_STATUS_DESCRIPTION.DISABLED;
  const isIssued = NFCe.status === INVOICE_STATUS_DESCRIPTION.ISSUED;
  const isInContingency =
    NFCe.status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY;
  const { page, limit } = usePagination();
  const {
    query,
    type,
    status,
    dateType,
    initialDate,
    finalDate,
    getFiltersPayload,
  } = useFilters();
  const updatedFilters = {
    query,
    type,
    status,
    dateType,
    initialDate,
    finalDate,
    ...getFiltersPayload(),
  };

  async function handleDeleteNFCe() {
    setIsLoadingDeleteInvoice(true);
    try {
      await NFCesRepository.deleteNFCe(NFCe.id);
      toastr.success('NFC-e deletada com sucesso');
      onChange(updatedFilters, { page, limit });
    } catch (err) {
      if (err.response) {
        toastr.warning(
          err.response.data.message ||
            'Ocorreu um erro ao deletar a NFC-e. Por favor, tente novamente'
        );
      } else {
        toastr.warning(
          'Ocorreu um erro ao deletar a NFC-e. Por favor, tente novamente'
        );
      }
    } finally {
      setIsLoadingDeleteInvoice(false);
    }
  }

  function onSuccessCorrectionLetterModal() {
    setShowCorrectionLetterModal(false);
    onChange(updatedFilters, { page, limit });
  }

  function onSuccessCancelInvoiceModal() {
    setShowCancelInvoiceModal(false);
    onChange(updatedFilters, { page, limit });
  }

  function handleOpenPDFDownloadPage() {
    window.open(NFCe.docPdfDownload);
  }

  async function consultNFCe(type) {
    setLoadingConsult(true);
    try {
      await NFCesRepository.consult(type, NFCe.id);
      onChange(updatedFilters, { page, limit });
      toastr.success('NFC-e consultada com sucesso.');
    } catch (err) {
      console.error('Ocorreu um erro ao consultar NFC-e.');
    }
    setLoadingConsult(false);
  }

  if (isDisabled) return null;

  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(NFCe)}
        className="btn-acoes"
        style={{ color: '#000000', fontSize: '14px' }}
      />
      {(isOpen || isOpenWithErrors) && (
        <FontAwesomeIcon
          onClick={() => setIsDeleteModalOpen(true)}
          icon={faTrashAlt}
          className="btn-acoes"
          style={{ color: '#e61610', fontSize: '14px' }}
        />
      )}
      {isInContingency && (
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => consultNFCe('contingency')}
          className="btn-acoes"
          style={{ color: '#444444', fontSize: '14px' }}
        />
      )}
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handleOpenPDFDownloadPage}
            className="btn-acoes"
            style={{ color: '#444444', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => consultNFCe('normal')}
            className="btn-acoes"
            style={{ color: '#444444', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => setShowCancelInvoiceModal(true)}
            className="btn-acoes"
            style={{ color: '#e61610', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faShareAltSquare}
            onClick={() => setIsShareNFCeModalOpen(true)}
            className="btn-acoes"
            style={{ color: '#72bb53', fontSize: '14px' }}
          />
        </>
      )}

      <AlertModal
        message="Deseja realmente excluir a nota?"
        show={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onHide={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDeleteNFCe}
        loading={isLoadingDeleteInvoice}
      />

      <CancelInvoiceModal
        show={showCancelInvoiceModal}
        onCancel={() => setShowCancelInvoiceModal(false)}
        onHide={() => setShowCancelInvoiceModal(false)}
        onSuccess={onSuccessCancelInvoiceModal}
        nfceSelected={NFCe}
      />

      <CorrectionLetterModal
        show={showCorrectionLetterModal}
        onCancel={() => setShowCorrectionLetterModal(false)}
        onHide={() => setShowCorrectionLetterModal(false)}
        onSuccess={onSuccessCorrectionLetterModal}
        nfeSelected={NFCe}
      />

      {isShareNFCeModalOpen && (
        <ShareNFCe
          show={isShareNFCeModalOpen}
          animation
          onHide={() => setIsShareNFCeModalOpen(false)}
          nfce={NFCe}
          onSubmit={() => setIsShareNFCeModalOpen(false)}
        />
      )}

      {loadingConsult && <Loader message="Aguarde, consultando NFC-e" />}
    </div>
  );
};

export default NFCeActions;
