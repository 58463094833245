import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
/* import { useKanbanScheduling } from '../../../hooks/usekanbanScheduling'; */
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { kanbanUtils } from '../utils';

export function KanbanCardBody({
  customerName,
  customerPhone,
  startScheduling,
  endScheduling,
  id,
  boxDescription,
  tagDirection = 'row',
  tagAlign = 'start',
  hasAppointment,
}) {
  const { formatDateTime } = kanbanUtils;
  /*   const { formatDateTime, getElapsedTime } = useKanbanScheduling(); */

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <div
          style={{
            minWidth: '24px',
            height: '24px',
            backgroundColor: '#C0C0C0',
            borderRadius: '9999px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon
            style={{ width: '14px', height: '14px' }}
            icon={faUser}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            fontSize: '11px',
          }}
        >
          <strong>{customerName}</strong>
          <small>{phoneMask(customerPhone)}</small>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: tagAlign,
          justifyContent: tagAlign,
          gap: '5px',
          fontSize: '9px',
          color: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: tagDirection,
            gap: '1rem',
          }}
        >
          <span
            style={{
              ...style.info,
              minWidth: '90px',
              backgroundColor: '#00A4D3',
              border: '1px solid #1D7391',
            }}
          >
            {startScheduling ? formatDateTime(startScheduling) : '-'}
          </span>
          {endScheduling && (
            <span
              style={{
                ...style.info,
                minWidth: '55px',
                backgroundColor: '#F0AD4E',
                border: '1px solid #C0C0C0',
              }}
            >
              {formatDateTime(endScheduling)}
              {/* {getElapsedTime(startScheduling, endScheduling)} */}
            </span>
          )}
        </div>
        {boxDescription && (
          <span
            style={{
              ...style.info,
              minWidth: '90px',
              backgroundColor: '#1D7391',
              border: '1px solid #1D7391',
            }}
          >
            {boxDescription}
          </span>
        )}
      </div>
      {hasAppointment ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '11px',
          }}
        >
          <Link to={{ pathname: `scheduling#${id}?kanban=true` }}>
            Abrir Agendamento
          </Link>
        </div>
      ) : null}
    </div>
  );
}

const style = {
  info: {
    borderRadius: '3px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '9px',
    fontWeight: 400,
  },
};
