import api from '../services/api';

export const getByCompanyId = async (companyId) => {
  try {
    const res = await api.get('/sale-print-config/' + companyId);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, data) => {
  try {
    await api.put('/sale-print-config/' + id, data);
  } catch (err) {
    throw err;
  }
};

export const create = async (body) => {
  const res = await api.post('/sale-print-config', body);
  return res.data;
};

export const SaleConfigRepository = {
  getByCompanyId,
  update,
  create,
};
