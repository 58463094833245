import React from 'react';
import {
  otherDetailsDefectsOptions,
  otherDetailsMalfuctionsOptions,
  otherDetailsObservationOptions,
  otherDetailsShowEmptyDiscountOptions,
} from './constants';
import { useFormContext } from 'react-hook-form';

export default function OtherDetails() {
  const { register, watch } = useFormContext();
  const loading = watch('loading');

  return (
    <div className="flex column gap-1 mt-05 p-1" style={{ marginLeft: '15px' }}>
      <div className="flex row gap-050 start align-center">
        <span>Desconto Geral zerado:</span>
        <select
          className="form-control foco-input w-150px"
          name="showEmptyDiscount"
          {...register('otherDetails.showEmptyDiscount')}
          disabled={loading}
        >
          {otherDetailsShowEmptyDiscountOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex row gap-050 start align-center">
        <span>Observações:</span>
        <select
          className="form-control foco-input w-150px"
          name="observation"
          {...register('otherDetails.observation')}
          disabled={loading}
        >
          {otherDetailsObservationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div
        className="flex row gap-050 start align-center"
        style={{ width: '300px' }}
      >
        <span style={{ width: '85px' }}>Defeitos:</span>
        <select
          className="form-control foco-input w-150px"
          name="defects"
          {...register('otherDetails.defects')}
          disabled={loading}
        >
          {otherDetailsDefectsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex row gap-050 start align-center">
        <span style={{ width: '85px' }}>Avarias:</span>
        <select
          className="form-control foco-input w-150px"
          name="malfunctions"
          {...register('otherDetails.malfunctions')}
          disabled={loading}
        >
          {otherDetailsMalfuctionsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
