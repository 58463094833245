import { useAuth } from 'contexts/auth';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faX } from '@fortawesome/free-solid-svg-icons';
import { HexColorPicker } from 'react-colorful';
import { KanbanSchedulingRepository } from 'v2/repositories/KanbanSchedulingRepository';
import { Modal } from 'react-bootstrap';

export const KanbanStatusModal = ({
  show,
  onCancel,
  onSuccess,
  id,
  name,
  createColumn,
  updateColumn,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState('#3472F7');
  const [statusName, setStatusName] = useState('' || name);
  const { companyId } = useAuth();

  const handleValidations = () => {
    if (!statusName) {
      toastr.error('Validação de Campos', 'Preencha o nome do status/coluna');
      return false;
    }

    if (!color) {
      toastr.error('Validação de Campos', 'Preencha uma cor');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!handleValidations()) return;
    try {
      const object = {
        ...(!!id ? { id } : {}),
        companyId,
        color,
        description: statusName,
      };
      const data = !id ? await create(object) : await update(object);

      onSuccess(data);
      toastr.success(
        `Status/Coluna ${!!id ? 'atualizado' : 'criado'} com sucesso!`
      );
    } catch (error) {
      console.log(error);
      toastr.error('Não foi possível salvar o status/coluna');
    }
  };

  const create = async (object) => {
    return await createColumn(object);
  };

  const update = async (object) => {
    return await updateColumn(object, companyId);
  };

  return (
    <Modal dialogClassName="modal-30w" show={show} animation={true}>
      <Modal.Body>
        <div
          className="gap-1"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className="gap-1 flex center">
            <input
              className="form-control foco-input"
              type="text"
              placeholder="Digite o nome do status/coluna"
              value={statusName}
              onChange={(e) => {
                setStatusName(e.target.value);
              }}
            />
            <div
              style={{
                border: '1px solid #E3E3E3',
                height: '35px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '5px',
                gap: '10px',
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <button
                className="button-icon"
                style={{
                  backgroundColor: color,
                  width: '10px',
                  height: '10px',
                }}
              >
                &nbsp;
              </button>
              <FontAwesomeIcon
                icon={faCaretDown}
                className="button-icon"
                style={{
                  width: '10px',
                  color: '#444444',
                }}
              />
            </div>
          </div>
          {showColorPicker && (
            <HexColorPicker color={color} onChange={setColor} />
          )}
          <div className="gap-1 flex align-center ">
            <button className="button button-green" onClick={handleSubmit}>
              {id ? 'Alterar Status/Coluna' : 'Adicionar Status'}
            </button>
            <FontAwesomeIcon
              icon={faX}
              onClick={onCancel}
              className="button-icon"
              style={{ width: '10px', color: '#444444' }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
